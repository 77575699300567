// source: https://github.com/WebDevSimplified/useful-custom-react-hooks/blob/main/src/2-useTimeout/useTimeout.js
import { MutableRefObject, useCallback, useEffect, useRef } from 'react';

export default function useTimeout(
	callback: () => void,
	delay: number
): { reset: () => void; clear: () => void } {
	const callbackRef = useRef(callback);
	const timeoutRef: MutableRefObject<NodeJS.Timeout | undefined> = useRef();

	useEffect(() => {
		callbackRef.current = callback;
	}, [callback]);

	const set = useCallback(() => {
		timeoutRef.current = setTimeout(() => callbackRef.current(), delay);
	}, [delay]);

	const clear = useCallback(() => {
		timeoutRef.current && clearTimeout(timeoutRef.current);
	}, []);

	useEffect(() => {
		set();
		return clear;
	}, [delay, set, clear]);

	const reset = useCallback(() => {
		clear();
		set();
	}, [clear, set]);

	return { reset, clear };
}

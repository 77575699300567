import { MessageBar, MessageBarType } from '@fluentui/react';
import { ElementContent } from 'react-markdown/lib/ast-to-react';
import { ReactMarkdownProps } from 'react-markdown/lib/complex-types';

type MarkdownNode = {
	children: (ElementContent & { tagName: string })[];
};
type MarkdownChild = ReactMarkdownProps & { props: ReactMarkdownProps };
export type AdmonitionProps = ReactMarkdownProps & {
	node: MarkdownNode;
	children: MarkdownChild[];
};

const Admonition = ({ node, ...props }: AdmonitionProps): JSX.Element => {
	const ensureChildren = ({ node, ...props }: AdmonitionProps) => {
		let children = props.children;
		if (node.children && node.children[0] && node.children[0].tagName === 'p') {
			children = props?.children[0]?.props.children;
		}
		return children;
	};

	const onRenderAdmonition = (
		messageBarType: MessageBarType,
		{ node, ...props }: AdmonitionProps
	) => {
		return (
			<MessageBar messageBarType={messageBarType} isMultiline={true}>
				{ensureChildren({ node, ...props })}
			</MessageBar>
		);
	};

	const admonitionRenderersMapping: { [type: string]: MessageBarType } = {
		warning: MessageBarType.warning,
		neutral: MessageBarType.info,
		negative: MessageBarType.severeWarning,
	};

	const messageBarType =
		(node?.properties?.type &&
			typeof node.properties.type === 'string' &&
			admonitionRenderersMapping[node.properties.type]) ||
		MessageBarType.info;

	return onRenderAdmonition(messageBarType, { node, ...props });
};

export default Admonition;

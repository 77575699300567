import React, { useState } from 'react';
import { useIntl } from '@ysoft/react-intl';
import {
	DefaultButton,
	mergeStyleSets,
	PrimaryButton,
	Stack,
} from '@fluentui/react';
import ModalHeader from '../../general-components/modals/ModalHeader';
import DevicePicker, { DevicePickerProps } from '../device-picker/DevicePicker';
import { SearchBox } from '../../general-components/SearchBox';
import { ModalContentStack } from '../../general-components/modals/ModalContentStack';
import useSearchedTerm from '../../hooks/useSearchedTerm';
import { CommonModalProps } from '../../general-components/modals/CommonModalProps';
import { useApiContext } from '../../libs/apiContext';
import { SuccessImageStackItem } from '../../general-components/SuccessImageStackItem';
import { SuccessCongratulationStackItem } from '../../general-components/SuccessCongratulationStackItem';
import ProgressButton from '../../general-components/ProgressButton';
import { ErrorMessageBar } from '../../general-components/ErrorMessageBar';
import ReactMarkdown from 'react-markdown';
import useSWR from 'swr';
import { SwrMutate } from '../../../types/swrMutate';
import { useConfigContext } from '../../config/configContext';

export type MigrateAnotherDeviceProps = CommonModalProps &
	DevicePickerProps & {
		mutateValidLicense: SwrMutate<Api.License | Record<string, never>>;
	};

const classes = mergeStyleSets({
	center: {
		textAlign: 'center',
	},
});

const MigrateAnotherDevice: React.FC<MigrateAnotherDeviceProps> = (props) => {
	const { t } = useIntl();
	const [selectedDeviceId, setSelectedDeviceId] = useState('');
	const { searchedTerm, setSearchedTerm } = useSearchedTerm('modal-q');
	const [isMigrating, setIsMigrating] = useState(false);
	const [showMigrationResult, setShowMigrationResult] = useState(false);
	const [error, setError] = useState<Api.ErrorResponse | undefined>(undefined);
	const api = useApiContext();
	const { config } = useConfigContext();

	const migrateSelectedDevice = () => {
		setIsMigrating(true);
		api
			.post(`/devices/${props.currentDeviceId}/migrate/${selectedDeviceId}`)
			.then(() => {
				props.mutateValidLicense();
				if (props.setSelectedDeviceId && selectedDeviceId) {
					props.setSelectedDeviceId(selectedDeviceId);
				}
				setShowMigrationResult(true);
			})
			.catch((err) => {
				setError(err.response ? err.response : err);
			})
			.finally(() => {
				setIsMigrating(false);
			});
	};

	const licensesFetcher = (url: string): Promise<Api.License[]> =>
		config.licenseToggle
			? api.get(url).then((res) => res.data)
			: new Promise((resolve, _) => {
					resolve([] as Api.License[]);
			  });

	const { data: licensesList, error: licensesFetcherError } = useSWR<
		Api.License[]
	>('/licenses', licensesFetcher);
	licensesFetcherError && setError(licensesFetcherError.response);

	const renderMigrationResult = (): JSX.Element => {
		return (
			<>
				<Stack
					tokens={{ childrenGap: 20 }}
					horizontalAlign="center"
					className={classes.center}
				>
					<SuccessImageStackItem />
					<SuccessCongratulationStackItem>
						{t('migrate-from__modal__success-congratulations')}
					</SuccessCongratulationStackItem>
					<Stack data-testid="migrate-modal_action-request-text">
						<ReactMarkdown>
							{t('migrate-from__modal__success-information')}
						</ReactMarkdown>

						{selectedDeviceId && props.currentDeviceId && (
							<>
								<ReactMarkdown>
									{t('migrate-from__modal__success-migration-result-info', {
										deviceId: props.currentDeviceId,
										deviceIdSource: selectedDeviceId,
									})}
								</ReactMarkdown>
								{config.licenseToggle &&
									t('migrate-from__modal__success-licenses-migrated-too')}
							</>
						)}
					</Stack>
				</Stack>
				<Stack horizontalAlign="end">
					<DefaultButton
						data-testid="omni-close"
						text={t('general__button-close')}
						onClick={props.onDismiss}
					/>
				</Stack>
			</>
		);
	};

	const renderSelectDeviceToMigrateFrom = (): JSX.Element => {
		return (
			<>
				<Stack>
					<Stack
						horizontal
						verticalAlign="center"
						horizontalAlign="space-between"
					>
						<p>{t('migrate-from__modal__select-info')}</p>
						<SearchBox
							data-testid="migrate-from-modal__search-bar"
							defaultValue={searchedTerm}
							onSearch={setSearchedTerm}
							onClear={() => setSearchedTerm('')}
						/>
					</Stack>
				</Stack>
				<DevicePicker
					{...(props as DevicePickerProps)}
					setSelectedDeviceId={setSelectedDeviceId}
					searchedTerm={searchedTerm}
					licenseInfo={{
						filterValidLicenseOnly: config.licenseToggle,
						allLicenses: licensesList,
					}}
				/>
				{error && <ErrorMessageBar error={error} />}
				<Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="end">
					<DefaultButton
						text={t('general__button-cancel')}
						onClick={props.onDismiss}
					/>
					{isMigrating ? (
						<ProgressButton>
							{t('migrate-from__modal__button-migrate')}
						</ProgressButton>
					) : (
						<PrimaryButton
							data-testid="migrate-from-modal-migrate-button"
							disabled={!selectedDeviceId}
							text={t('migrate-from__modal__button-migrate')}
							onClick={migrateSelectedDevice}
						/>
					)}
				</Stack>
			</>
		);
	};

	return (
		<ModalContentStack data-testid="migrate-from-modal">
			<ModalHeader
				title={t('migrate-from__modal__heading')}
				onDismiss={props.onDismiss}
			/>
			{showMigrationResult
				? renderMigrationResult()
				: renderSelectDeviceToMigrateFrom()}
		</ModalContentStack>
	);
};

export default MigrateAnotherDevice;

import React from 'react';
import {
	TextField as FluentTextField,
	ITextFieldProps as FluentITextFieldProps,
} from '@fluentui/react';
import { Controller, Control, RegisterOptions } from 'react-hook-form';

export interface HookFormProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	control: Control<any>;
	name: string;
	rules?: RegisterOptions;
	defaultValue?: string;
}

export const TextField: React.FC<
	HookFormProps &
		FluentITextFieldProps & {
			'data-testid': string;
			backendValidations?: {
				errors?: Api.Errors;
				errorMessage: string;
			};
		}
> = ({ 'data-testid': dataTestId, backendValidations, ...props }) => {
	return (
		<div data-testid={dataTestId}>
			<Controller
				name={props.name}
				control={props.control}
				rules={props.rules}
				defaultValue={props.defaultValue || ''}
				render={({
					field: { onChange, onBlur, name: fieldName, value },
					fieldState: { error },
				}) => {
					const backendValidationsTriggered =
						backendValidations?.errors &&
						Object.prototype.hasOwnProperty.call(
							backendValidations.errors,
							fieldName
						);
					return (
						<FluentTextField
							{...props}
							data-testid="text"
							onChange={(e, newValue) => {
								if (props.onChange) {
									props.onChange(e, newValue);
								}
								onChange(e, newValue);
							}}
							value={value}
							onBlur={onBlur}
							name={fieldName}
							errorMessage={
								error ? (
									<div data-testid="error-message">{error.message}</div>
								) : backendValidationsTriggered ? (
									<div data-testid="error-message">
										{backendValidations?.errorMessage}
									</div>
								) : undefined
							}
							defaultValue={undefined}
						/>
					);
				}}
			/>
		</div>
	);
};

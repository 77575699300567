import React from 'react';
import { mergeStyleSets, Stack } from '@fluentui/react';
import { IStackItemProps } from '@fluentui/react/lib/components/Stack/StackItem/StackItem.types';

const classes = mergeStyleSets({
	successImageStackItem: {
		width: '100%',
		maxWidth: '20rem',
		textAlign: 'center',
	},
});

export const SuccessImageStackItem: React.FC<IStackItemProps> = (props) => {
	return (
		<Stack.Item
			className={classes.successImageStackItem}
			align="center"
			{...props}
		>
			<img
				src="/assets/OmniSuccess.svg"
				alt="Success tick"
				data-testid="omni-success"
			/>
		</Stack.Item>
	);
};

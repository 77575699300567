import React from 'react';
import { Text, Icon, mergeStyleSets, Stack } from '@fluentui/react';
import { useIntl } from '@ysoft/react-intl';
import { omniTheme } from '../theme';

const classNames = mergeStyleSets({
	keycloak_not_working__page: {
		textAlign: 'center',
		color: omniTheme.palette.neutralSecondary,
		height: 'calc(100vh - 3rem)',
	},
	heading: {
		margin: 0,
		heading: 0,
	},
	keycloak_not_working__icon_bg: {
		height: '5rem',
		width: '5rem',
		borderRadius: '5rem',
		backgroundColor: omniTheme.palette.neutralLighter,
	},
	keycloak_not_working__icon: {
		color: omniTheme.palette.themePrimary,
	},
});

const headerStyle = {
	root: {
		padding: '0.3rem 1rem',
		background: omniTheme.palette.themePrimary,
		color: omniTheme.palette.white,
	},
};

const KeycloakNotWorkingPage: React.FC = () => {
	const { t } = useIntl();

	return (
		<>
			<Stack
				horizontal
				styles={headerStyle}
				data-testid="layout-header"
				horizontalAlign="space-between"
				verticalAlign="center"
			>
				<Stack.Item>
					<h1 className={classNames.heading}>{t('app__title')}</h1>
				</Stack.Item>
			</Stack>
			<Stack
				data-testid="keycloak-not-working__page"
				horizontalAlign="center"
				verticalAlign="center"
				className={classNames.keycloak_not_working__page}
			>
				<Stack
					className={classNames.keycloak_not_working__icon_bg}
					horizontalAlign="center"
					verticalAlign="center"
				>
					<Stack.Item align="center">
						<Text variant="superLarge">
							<Icon
								className={classNames.keycloak_not_working__icon}
								data-testid="header_warning-icon"
								iconName="Lock"
							/>
						</Text>
					</Stack.Item>
				</Stack>
				<Stack>
					<Text variant="xxLarge">
						<p>{t('general__keycloak_not_working_line_one')}</p>
						<p>{t('general__keycloak_not_working_line_two')}</p>
					</Text>
				</Stack>
			</Stack>
		</>
	);
};

export default KeycloakNotWorkingPage;

import React from 'react';
import { MessageBar, IMessageBarProps, MessageBarType } from '@fluentui/react';

export enum NotificationType {
	Error = 1,
	Info = 0,
}
export interface INotificationBarProps {
	isVisible: boolean;
	text: string;
	notificationType: NotificationType;
}

export const NotificationBar: React.FC<
	INotificationBarProps & IMessageBarProps
> = ({
	isVisible,
	text,
	notificationType,
	...props
}: INotificationBarProps) => {
	const messageBarType =
		notificationType === NotificationType.Error
			? MessageBarType.error
			: MessageBarType.success;
	return (
		<>
			{isVisible && (
				<MessageBar
					data-testid="notification-bar"
					{...props}
					messageBarType={messageBarType}
				>
					{text}
				</MessageBar>
			)}
		</>
	);
};

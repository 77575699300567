import React from 'react';
import { mergeStyleSets, Stack } from '@fluentui/react';
import { IStackProps } from '@fluentui/react/lib/components/Stack/Stack.types';

const classes = mergeStyleSets({
	modalContent: {
		width: 'calc(100vw - 5rem)',
		maxWidth: '40rem',
		padding: '1rem',
	},
});

export const ModalContentStack: React.FC<
	IStackProps & {
		'data-testid': string;
	}
> = ({ 'data-testid': dataTestId, children, ...props }) => {
	return (
		<Stack
			data-testid={dataTestId}
			className={classes.modalContent}
			tokens={{ childrenGap: 10 }}
			{...props}
		>
			{children}
		</Stack>
	);
};

import { useIntl } from '@ysoft/react-intl';
import { useConfigContext } from '../../config/configContext';

export enum GatewayConfigurationMode {
	P2S,
	S2S,
	LicenseError,
}

const useConfigurationValidation = () => {
	const { t } = useIntl();
	const { config } = useConfigContext();

	const validateJSON = (value: string): string => {
		try {
			JSON.parse(value);
			return '';
		} catch {
			return t('port-configuration__text-field-validation-error');
		}
	};

	const convertLicenseTypeToGatewayMode = (
		license: Api.License | undefined | Record<string, never>
	) => {
		if (license?.type) {
			switch (license.type) {
				case 'SiteToSite':
					return 's2s';
				case 'PointToSite':
					return 'p2s';
			}
		}
		return undefined;
	};

	const validateGatewayConfigurationMode = (
		value: string,
		license: Api.License | undefined | Record<string, never>
	): string => {
		let moduleTwin: Api.ModuleTwin;

		try {
			moduleTwin = JSON.parse(value) as Api.ModuleTwin;
		} catch (error) {
			return t('port-configuration__text-field-validation-error');
		}

		const gatewayConfigurationMode = moduleTwin.gatewayConfiguration?.mode;

		if (config.licenseToggle) {
			if (license === undefined) {
				return t('port-configuration__no-license-sent');
			}

			const licenseType = convertLicenseTypeToGatewayMode(license);

			if (
				gatewayConfigurationMode !== undefined &&
				!areSame(gatewayConfigurationMode, licenseType)
			) {
				return t(
					'port-configuration__wrong-gateway-configuration-and-license-type'
				);
			}
		} else {
			if (
				gatewayConfigurationMode !== undefined &&
				!isGatewayConfigurationMode(gatewayConfigurationMode)
			) {
				return t(
					'port-configuration__wrong-gateway-configuration-and-available-license-type'
				);
			}
		}
		return '';
	};

	const getValidGatewayConfigurationMode = (
		value: string,
		license: Api.License | undefined | Record<string, never>
	): GatewayConfigurationMode => {
		let moduleTwin: Api.ModuleTwin;

		try {
			moduleTwin = JSON.parse(value) as Api.ModuleTwin;
		} catch (error) {
			return GatewayConfigurationMode.LicenseError;
		}

		const gatewayConfigurationMode = moduleTwin?.gatewayConfiguration?.mode as
			| 's2s'
			| 'p2s'
			| undefined;

		if (config.licenseToggle) {
			if (license === undefined) {
				return GatewayConfigurationMode.LicenseError;
			}

			const licenseType = convertLicenseTypeToGatewayMode(license);

			if (gatewayConfigurationMode !== undefined) {
				if (areSame(gatewayConfigurationMode, licenseType)) {
					return toValidGatewayConfigurationMode(gatewayConfigurationMode);
				} else {
					return GatewayConfigurationMode.LicenseError;
				}
			} else {
				return toValidGatewayConfigurationMode(licenseType);
			}
		} else {
			if (gatewayConfigurationMode !== undefined) {
				return toValidGatewayConfigurationMode(gatewayConfigurationMode);
			} else {
				return GatewayConfigurationMode.P2S;
			}
		}
	};

	const areSame = (
		gatewayConfigurationMode: string,
		licenseType: 's2s' | 'p2s' | undefined
	) => gatewayConfigurationMode === licenseType;

	const toValidGatewayConfigurationMode = (
		gatewayConfigurationMode: 's2s' | 'p2s' | undefined
	) => {
		if (
			gatewayConfigurationMode?.toLocaleUpperCase() ===
			GatewayConfigurationMode[GatewayConfigurationMode.P2S]
		) {
			return GatewayConfigurationMode.P2S;
		}

		if (
			gatewayConfigurationMode?.toLocaleUpperCase() ===
			GatewayConfigurationMode[GatewayConfigurationMode.S2S]
		) {
			return GatewayConfigurationMode.S2S;
		}

		return GatewayConfigurationMode.LicenseError;
	};

	const isGatewayConfigurationMode = (
		gatewayConfigurationMode: string
	): boolean => {
		if (
			gatewayConfigurationMode.toLocaleUpperCase() ===
				GatewayConfigurationMode[GatewayConfigurationMode.P2S] ||
			gatewayConfigurationMode.toLocaleUpperCase() ===
				GatewayConfigurationMode[GatewayConfigurationMode.S2S]
		) {
			return true;
		}

		return false;
	};

	const validateGatewayConfiguration = (
		license: Api.License | undefined | Record<string, never>,
		value: string
	): string => {
		const name = 'gateway';
		const mandatoryKeys = ['mode'];

		const moduleTwin = JSON.parse(value) as Api.ModuleTwin;

		if (!moduleTwin.gatewayConfiguration) {
			return t('port-configuration__missing-configuration', { name });
		}
		let isValid = getMissingProperties(
			moduleTwin?.gatewayConfiguration,
			mandatoryKeys,
			name
		);
		if (isValid !== '') {
			return isValid;
		}
		isValid = isEmpty(moduleTwin?.gatewayConfiguration?.mode, 'mode');
		if (isValid !== '') {
			return isValid;
		}

		const validationType = getValidGatewayConfigurationMode(value, license);

		switch (validationType) {
			case GatewayConfigurationMode.P2S:
				if (moduleTwin?.gatewayConfiguration?.mode?.toLowerCase() !== 'p2s') {
					return t('port-configuration__wrong-gateway-mode');
				}
				break;
			case GatewayConfigurationMode.S2S:
				if (moduleTwin?.gatewayConfiguration?.mode?.toLowerCase() !== 's2s') {
					return t('port-configuration__wrong-gateway-mode');
				}
				break;
			case GatewayConfigurationMode.LicenseError:
			default:
				return t('port-configuration__wrong-gateway-mode');
		}
		return '';
	};

	const validateDnsServerConfiguration = (value: string): string => {
		const name = 'DNS Server';
		const mandatoryKeys = [
			'dnsZoneName',
			'azureResourceGroupName',
			'tenantId',
			'servicePrincipalClientId',
			'servicePrincipalClientSecret',
			'azureSubscriptionId',
		] as const;
		const moduleTwin = JSON.parse(value) as Api.ModuleTwin;
		const dnsServerConfiguration = moduleTwin?.dnsServerConfiguration;
		if (!dnsServerConfiguration) {
			return '';
		}
		const missingProperties = getMissingProperties(
			dnsServerConfiguration,
			mandatoryKeys,
			name
		);
		if (missingProperties !== '') {
			return missingProperties;
		}
		for (const key of mandatoryKeys) {
			const isValid = isEmpty(dnsServerConfiguration[key], key);
			if (isValid !== '') {
				return isValid;
			}
			const isPlaceholder = isPlaceholderIn(dnsServerConfiguration[key], key);
			if (isPlaceholder !== '') {
				return isPlaceholder;
			}
		}
		return '';
	};

	const validateOpenVpnConfiguration = (value: string): string => {
		const name = 'open VPN';
		const mandatoryKeys = [
			'openVpnClientOptions',
			'caCertificate',
			'clientCertificate',
			'clientKey',
			'tlsAuth',
		] as const;

		const moduleTwin = JSON.parse(value) as Api.ModuleTwin;
		const openVpnConfiguration = moduleTwin?.openVpnConfiguration;
		if (!openVpnConfiguration) {
			return t('port-configuration__missing-configuration', { name });
		}
		const missingProperties = getMissingProperties(
			openVpnConfiguration,
			mandatoryKeys,
			name
		);
		if (missingProperties !== '') {
			return missingProperties;
		}
		for (const mandatoryKeyName of mandatoryKeys) {
			const isValid = isEmpty(
				openVpnConfiguration[mandatoryKeyName],
				mandatoryKeyName
			);
			if (isValid !== '') {
				return isValid;
			}
			const isPlaceholder = isPlaceholderIn(
				openVpnConfiguration[mandatoryKeyName],
				mandatoryKeyName
			);
			if (isPlaceholder !== '') {
				return isPlaceholder;
			}
		}
		return '';
	};

	const validateDhcpConfiguration = (
		license: Api.License | undefined | Record<string, never>,
		value: string
	): string => {
		const name = 'DHCP';
		const mandatoryKeys = [
			'mask',
			'dns',
			'leaseTime',
			'startIp',
			'endIp',
			'gatewayIp',
		] as const;

		const validationType = getValidGatewayConfigurationMode(value, license);

		const moduleTwin = JSON.parse(value) as Api.ModuleTwin;

		const dhcpConfiguration = moduleTwin?.dhcpConfiguration;

		switch (validationType) {
			case GatewayConfigurationMode.P2S:
				if (dhcpConfiguration) {
					return t('port-configuration__not-allowed-configuration', { name });
				}
				break;
			case GatewayConfigurationMode.S2S:
				if (!dhcpConfiguration) {
					return t('port-configuration__missing-configuration', { name });
				}
				break;
			case GatewayConfigurationMode.LicenseError:
			default:
				return t('port-configuration__missing-configuration', { name });
		}

		if (!dhcpConfiguration) {
			return '';
		}
		let isValid = getMissingProperties(dhcpConfiguration, mandatoryKeys, name);
		if (isValid !== '') {
			return isValid;
		}
		for (const mandatoryKeyName of mandatoryKeys) {
			if (mandatoryKeyName !== 'leaseTime') {
				const isValid = isEmpty(
					dhcpConfiguration[mandatoryKeyName],
					mandatoryKeyName
				);
				if (isValid !== '') {
					return isValid;
				}
				const isPlaceholder = isPlaceholderIn(
					dhcpConfiguration[mandatoryKeyName],
					mandatoryKeyName
				);
				if (isPlaceholder !== '') {
					return isPlaceholder;
				}
			}
		}
		const keys = ['mask', 'startIp', 'endIp', 'gatewayIp'] as const;
		for (const key of keys) {
			if (!isIpInCorrectFormat(dhcpConfiguration[key])) {
				return t('port-configuration__field-value-incorrect-format', {
					fieldName: key,
				});
			}
		}
		isValid = dnsValidationInDhcp(dhcpConfiguration.dns);
		if (isValid !== '') {
			return isValid;
		}
		isValid = staticLeasesValidation(dhcpConfiguration.staticLeases);
		if (isValid !== '') {
			return isValid;
		}
		isValid = leaseTimeValidation(dhcpConfiguration.leaseTime);
		if (isValid !== '') {
			return isValid;
		}
		return '';
	};

	const leaseTimeValidation = (value: string): string => {
		let num = 0;
		try {
			num = parseInt(value);
		} catch (error) {
			return t('port-configuration__field-value-incorrect-format', {
				fieldName: 'leaseTime',
			});
		}

		const validLeaseTime = num <= 600 && num >= 60;

		if (!validLeaseTime) {
			return t('port-configuration__field-value-incorrect-format', {
				fieldName: 'leaseTime',
			});
		}

		return '';
	};

	const isIpInCorrectFormat = (value: string): boolean => {
		return /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
			value
		);
	};

	const dnsValidationInDhcp = (value: string): string => {
		if (!value) {
			return '';
		}
		const dnsSplitted = value?.split(',');
		if (!dnsSplitted || dnsSplitted.length === 0) {
			return t('port-configuration__field-value-incorrect-format', {
				fieldName: 'dns',
			});
		}
		for (const dnsIp of dnsSplitted) {
			if (!isIpInCorrectFormat(dnsIp)) {
				return t('port-configuration__field-value-incorrect-format', {
					fieldName: 'dns',
				});
			}
		}
		return '';
	};

	const staticLeasesValidation = (value: string): string => {
		if (!value) {
			return '';
		}
		if (value.substr(value.length - 1) === ';')
			return t('port-configuration__redundant-semicolon');
		const macRegExp = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
		const errorMessage = t('port-configuration__field-value-incorrect-format', {
			fieldName: 'staticLeases',
		});
		for (const lease of value?.split(';')) {
			const macAndIp = lease?.split(',', 2);
			if (
				!macAndIp ||
				macAndIp.length < 2 ||
				!macRegExp.test(macAndIp[0]) ||
				!isIpInCorrectFormat(macAndIp[1])
			) {
				return errorMessage;
			}
		}
		return '';
	};

	const validatePortForwardingRules = (value: string): string => {
		const name = 'port forwarding';
		const mandatoryKeys = [
			'omniBridgeLocalNetworkInterfacePortNumber',
			'destinationPortNumber',
			'destinationIpAddress',
			'transportLayerProtocol',
		] as const;
		const moduleTwin = JSON.parse(value) as Api.ModuleTwin;
		if (!moduleTwin?.portForwardingRules) {
			return '';
		}

		if (moduleTwin?.portForwardingRules.rulesToCloudNetwork) {
			for (const rule of moduleTwin?.portForwardingRules.rulesToCloudNetwork) {
				const isValid = getMissingProperties(rule, mandatoryKeys, name);
				if (isValid !== '') {
					return isValid;
				}
			}
		}

		if (moduleTwin?.portForwardingRules.rulesToNetworkBehindOmniBridge) {
			for (const rule of moduleTwin?.portForwardingRules
				.rulesToNetworkBehindOmniBridge) {
				const isValid = getMissingProperties(rule, mandatoryKeys, name);
				if (isValid !== '') {
					return isValid;
				}
			}
		}

		const portForwardingRules = moduleTwin?.portForwardingRules;
		const validatedRulesProperties = [
			'rulesToNetworkBehindOmniBridge',
			'rulesToCloudNetwork',
		] as const;
		const numberKeys = [
			'omniBridgeLocalNetworkInterfacePortNumber',
			'destinationPortNumber',
		] as const;
		const stringKeys = [
			'destinationIpAddress',
			'transportLayerProtocol',
		] as const;
		const destinationIpAddress = 'destinationIpAddress';
		const transportLayerProtocol = 'transportLayerProtocol';
		for (const rulesPropertyName of validatedRulesProperties) {
			if (portForwardingRules[rulesPropertyName]) {
				for (const rule of portForwardingRules[rulesPropertyName]) {
					for (const key of numberKeys) {
						if (!isPortInRange(rule[key])) {
							return t('port-configuration__field-value-incorrect-format', {
								fieldName: key,
							});
						}
					}

					for (const key of stringKeys) {
						const isForwardingRulesEmpty = isEmpty(rule[key], key);
						if (isForwardingRulesEmpty !== '') {
							return isForwardingRulesEmpty;
						}

						const isPlaceholder = isPlaceholderIn(rule[key], key);

						if (isPlaceholder !== '') {
							return isPlaceholder;
						}
					}

					if (!isIpInCorrectFormat(rule[destinationIpAddress])) {
						return t('port-configuration__field-value-incorrect-format', {
							fieldName: destinationIpAddress,
						});
					}

					if (!isValidTransportLayerProtocol(rule[transportLayerProtocol])) {
						return t('port-configuration__field-value-incorrect-format', {
							fieldName: transportLayerProtocol,
						});
					}
				}
			}
		}

		return '';
	};

	const getMissingProperties = (
		input:
			| Api.DhcpConfiguration
			| Api.OpenVpnConfiguration
			| Api.DnsServerConfiguration
			| Api.PortForwardingRules
			| Api.PortForwardingRule
			| Api.GatewayConfiguration,
		mandatoryKeys: readonly string[],
		name: string
	): string => {
		const inputKeys = Object.keys(input);
		const validationResult = mandatoryKeys.reduce((acc, current) => {
			if (!inputKeys.includes(current)) {
				acc.push(`"${current}"`);
			}
			return acc;
		}, [] as string[]);
		if (validationResult.length) {
			return t('port-configuration__missing-configuration-properties', {
				name,
				result: validationResult.join(', '),
			});
		}
		return '';
	};

	const isEmpty = (value: unknown, name: string): string => {
		if (typeof value !== 'string') {
			return t('port-configuration__field-value-incorrect-format', {
				fieldName: name,
			});
		}
		if (value.trim() === '') {
			return t('port-configuration__property-cannot-be-empty', { name });
		}
		return '';
	};

	const isValidTransportLayerProtocol = (protocolName: string): boolean => {
		return (
			typeof protocolName === 'string' &&
			(protocolName.toLowerCase() === 'tcp' ||
				protocolName.toLowerCase() === 'udp')
		);
	};

	const isPortInRange = (portValue: number): boolean => {
		return portValue > 0 && portValue <= 65535;
	};

	const isPlaceholderIn = (value: unknown, name: string): string => {
		if (typeof value !== 'string') {
			return '';
		}
		if (value.trim() === '[INSERT_HERE]') {
			return t('port-configuration__field-value-contains-placeholder', {
				name,
			});
		}
		return '';
	};

	return {
		validations: {
			validateJSON,
			validateGatewayConfigurationMode: validateGatewayConfigurationMode,
			validateOpenVpnConfiguration,
			validateGatewayConfiguration,
			validateDnsServerConfiguration,
			validateDhcpConfiguration,
			validatePortForwardingRules,
		},
	};
};

const ExportFunction = (
	...params: Parameters<typeof useConfigurationValidation>
): ReturnType<typeof useConfigurationValidation> =>
	useConfigurationValidation(...params);

export default ExportFunction;

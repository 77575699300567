import React from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import { getTheme, mergeStyles, Stack, IStackTokens } from '@fluentui/react';
import { useIntl } from '@ysoft/react-intl';
import { useApiContext } from '../../libs/apiContext';
import { generateLoading } from '../../libs/generateLoading';
import Markdown from '../../general-components/Markdown';

type DocPath = {
	path: string;
};

const theme = getTheme();

const spacing: IStackTokens = {
	padding: '0 1rem 1rem 1rem',
};

const documentationClass = mergeStyles([
	{
		backgroundColor: theme.palette.white,
	},
]);

const Documentation: React.FC = () => {
	const api = useApiContext();
	let { path } = useParams<DocPath>();
	const { t } = useIntl();
	const docFetcher = (url: string) => api.get(url).then((res) => res.data);
	const { data: menu } = useSWR<Api.DocumentationItem>(
		'/documentation/menu.json',
		docFetcher
	);

	if (!path) {
		path = menu?.path || '';
	}

	const { data: content, error } = useSWR<string>(
		`/documentation/${path}`,
		docFetcher
	);

	const onRenderContent = (content: string | undefined, error: unknown) => {
		if (error) {
			return (
				<Markdown
					content={t('documentation__page-not-found-error__markdown')}
				/>
			);
		}

		if (!content && content === undefined) {
			return generateLoading();
		}

		if (!content && content === '') {
			return (
				<Markdown
					content={t('documentation__page-not-found-error__markdown')}
				/>
			);
		}

		return <Markdown content={content} />;
	};

	return (
		<Stack tokens={spacing} data-testid="documentation-article">
			<Stack.Item
				tokens={spacing}
				className={documentationClass}
				data-testid="content"
			>
				{onRenderContent(content, error)}
			</Stack.Item>
		</Stack>
	);
};

export default Documentation;

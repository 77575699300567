import React from 'react';
import {
	DefaultButton,
	IColumn,
	mergeStyleSets,
	SelectionMode,
	Stack,
} from '@fluentui/react';
import { useIntl } from '@ysoft/react-intl';
import { DetailsList } from '../../general-components/DetailsList';
import { generateLoading } from '../../libs/generateLoading';
import { useHistory } from 'react-router-dom';
import { ErrorMessageBar } from '../../general-components/ErrorMessageBar';
import useInfiniteScrolling from '../../hooks/useInfiniteScrolling';
import SelectTenantPrompt from './SelectCustomerPrompt';
import { useUserContext } from '../../libs/userContext';
import useSearchedTerm from '../../hooks/useSearchedTerm';
import HeadingWithSearchForDevices from '../HeadingWithSearchForDevices';

const DevicesList: React.FC = () => {
	const { t } = useIntl();
	const history = useHistory();
	const {
		searchedTerm,
		setSearchedTerm,
		additionalSearchedTerm,
		setAdditionalSearchedTerm,
	} = useSearchedTerm('q', undefined, undefined, 'status');
	const deviceStatus: Api.DeviceStatus =
		'Configured' === additionalSearchedTerm ||
		'NotConfigured' === additionalSearchedTerm ||
		'All' === additionalSearchedTerm
			? additionalSearchedTerm
			: undefined;
	const setDeviceStatus = (deviceStatus: Api.DeviceStatus) => {
		setAdditionalSearchedTerm(deviceStatus ?? '');
	};
	const { isPartnerPortalUserOnlyAndWithoutPartnerRole } = useUserContext();
	const { swrData, loadMoreTriggerRef, hasReachedEnd } =
		useInfiniteScrolling<Api.DevicesList>(
			searchedTerm,
			'/devices',
			{ revalidateAll: true, revalidateOnFocus: true, revalidateOnMount: true },
			deviceStatus && deviceStatus !== 'All'
				? [`status=${deviceStatus}`]
				: undefined
		);

	const classes = mergeStyleSets({
		contentClass: {
			padding: '0rem 1rem 0 1rem',
			margin: '0 auto',
			maxWidth: '75rem',
		},
		fullHeight: {
			minHeight: '100%',
		},
	});

	const devices = swrData.data
		? swrData.data.map((response) => response.items).flat()
		: [];

	const onRenderConfigureButton = (device: Api.Device): JSX.Element => {
		return (
			<DefaultButton
				data-testid="omni-bridge-devices-list__configure-button"
				text={t('omni-bridge-devices-list__configure-button')}
				onClick={() => configureDevice(device.id)}
			/>
		);
	};
	const onRenderConfiguration = (device: Api.Device): JSX.Element => {
		let displayText = '';
		switch (device.status) {
			case 'Configured':
				displayText = t('omni-bridge-devices-list__status_configured');
				break;
			case 'NotConfigured':
				displayText = t('omni-bridge-devices-list__status_not_configured');
				break;
			default:
				displayText = t('omni-bridge-devices-list__status_none');
				break;
		}
		return (
			<Stack verticalAlign="center" className={classes.fullHeight}>
				<Stack.Item data-testid="column:applicationStatus">
					<span>{displayText}</span>
				</Stack.Item>
			</Stack>
		);
	};

	const configureDevice = (id: string) => {
		history.push(`/devices/${id}`);
	};

	const deviceColumns: IColumn[] = [
		{
			key: 'deviceId',
			name: t('omni-bridge-devices-list__device-id'),
			fieldName: 'deviceId',
			minWidth: 100,
			isMultiline: true,
		},
		{
			key: 'status',
			name: t('omni-bridge-devices-list__status'),
			fieldName: 'status',
			minWidth: 200,
			onRender: onRenderConfiguration,
		},
		{
			key: 'configureButton',
			name: '',
			fieldName: '',
			minWidth: 120,
			maxWidth: 150,
			onRender: onRenderConfigureButton,
		},
	];

	return (
		<div className={classes.contentClass}>
			<Stack tokens={{ childrenGap: 10 }}>
				<HeadingWithSearchForDevices
					data-testid="omni-bridge-devices__heading"
					headingTitle={t('omni-bridges')}
					searchBoxPlaceholder={t(
						'omni-bridge-devices-list__search-box__placeholder'
					)}
					searchedTerm={searchedTerm}
					setSearchedTerm={setSearchedTerm}
					deviceStatus={deviceStatus}
					setDeviceStatus={setDeviceStatus}
				/>
				{isPartnerPortalUserOnlyAndWithoutPartnerRole ? (
					<p data-testid="error__no-access">
						<ErrorMessageBar errorMessage={t('error__no-access')} />
					</p>
				) : swrData.error ? (
					swrData.error.response.data.specificStatusCode === '404.10' ? (
						<SelectTenantPrompt />
					) : (
						<p data-testid="error">
							<ErrorMessageBar error={swrData.error.response} />
						</p>
					)
				) : !swrData.data ? (
					generateLoading()
				) : devices.length > 0 ? (
					<DetailsList
						data-testid="omni-bridge-devices-list"
						items={devices}
						columns={deviceColumns}
						selectionMode={SelectionMode.none}
					/>
				) : (
					<p data-testid="empty-omni-bridge-devices-list">
						{t('omni-bridge-devices-list__empty-list')}
					</p>
				)}
				{!hasReachedEnd && (
					<div ref={loadMoreTriggerRef} style={{ paddingBottom: 2 }} />
				)}
			</Stack>
		</div>
	);
};

export default DevicesList;

import React, { useState } from 'react';
import { useIntl } from '@ysoft/react-intl';
import { DefaultButton, PrimaryButton, Stack } from '@fluentui/react';
import ModalHeader from '../../general-components/modals/ModalHeader';
import DevicePicker, { DevicePickerProps } from '../device-picker/DevicePicker';
import { SearchBox } from '../../general-components/SearchBox';
import { ModalContentStack } from '../../general-components/modals/ModalContentStack';
import useSearchedTerm from '../../hooks/useSearchedTerm';
import { CommonModalProps } from '../../general-components/modals/CommonModalProps';

export type LoadAnotherDeviceConfigurationProps = CommonModalProps &
	DevicePickerProps;

const LoadAnotherDeviceConfiguration: React.FC<
	LoadAnotherDeviceConfigurationProps
> = (props) => {
	const { t } = useIntl();
	const [selectedDeviceId, setSelectedDeviceId] = useState('');
	const { searchedTerm, setSearchedTerm } = useSearchedTerm('modal-q');

	return (
		<ModalContentStack data-testid="load-configuration-from-modal">
			<ModalHeader
				title={t('copy-configuration-from__modal__heading')}
				onDismiss={props.onDismiss}
			/>
			<Stack>
				<Stack
					horizontal
					verticalAlign="center"
					horizontalAlign="space-between"
				>
					<p>{t('copy-configuration-from__modal__select-info')}</p>
					<SearchBox
						data-testid="load-configuration-from-modal__search-bar"
						defaultValue={searchedTerm}
						onSearch={setSearchedTerm}
						onClear={() => setSearchedTerm('')}
					/>
				</Stack>
			</Stack>
			<DevicePicker
				{...(props as DevicePickerProps)}
				setSelectedDeviceId={setSelectedDeviceId}
				searchedTerm={searchedTerm}
			/>
			<Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="end">
				<DefaultButton
					text={t('general__button-cancel')}
					onClick={props.onDismiss}
				/>
				<PrimaryButton
					data-testid="load-configuration-from-modal-load-configuration-button"
					disabled={!selectedDeviceId}
					text={t('copy-configuration-from__modal__button-load-configuration')}
					onClick={() => {
						if (props.setSelectedDeviceId && selectedDeviceId) {
							props.setSelectedDeviceId(selectedDeviceId);
						}
						props.onDismiss();
					}}
				/>
			</Stack>
		</ModalContentStack>
	);
};

export default LoadAnotherDeviceConfiguration;

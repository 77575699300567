import React from 'react';
import { Modal } from '@fluentui/react';
import MigrateAnotherDevice, {
	MigrateAnotherDeviceProps,
} from './MigrateAnotherDevice';

const MigrateAnotherDeviceModal: React.FC<MigrateAnotherDeviceProps> = (
	props
) => {
	return (
		<Modal isOpen={props.isOpen} onDismiss={props.onDismiss}>
			<MigrateAnotherDevice {...props} />
		</Modal>
	);
};

export default MigrateAnotherDeviceModal;

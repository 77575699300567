import * as React from 'react';
import {
	getTheme,
	IconButton,
	IStyle,
	mergeStyles,
	mergeStyleSets,
	Panel,
	PanelType,
	Stack,
} from '@fluentui/react';
import { ActionsPanelElements } from './ActionsPanelElements';
import { tabletBreakpoint } from '../../support/constants';
import { useBoolean } from '@fluentui/react-hooks';

const theme = getTheme();

const classes = mergeStyleSets({
	button: {
		padding: 0,
		fontWeight: 'bold',
	},
	hamburgerStack: {
		height: '100%',
	},
	iconButton: {
		backgroundColor: theme.palette.white,
	},
	panel: {
		maxWidth: '100%',
	},
});

const loginPanelStyles: IStyle = {};
loginPanelStyles['@media(max-width: ' + tabletBreakpoint + ')'] = {
	display: 'none',
};
const loginPanelClass = mergeStyles(loginPanelStyles);

const hamburgerStyles: IStyle = { display: 'none', height: '100%' };
hamburgerStyles['@media(max-width: ' + tabletBreakpoint + ')'] = {
	display: 'block',
};
const hamburgerClass = mergeStyles(hamburgerStyles);

export const ActionsPanel: React.FC = () => {
	const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
		useBoolean(false);

	return (
		<>
			<Stack className={loginPanelClass}>
				<ActionsPanelElements />
			</Stack>
			<Stack verticalAlign="center" className={hamburgerClass}>
				<IconButton
					iconProps={{ iconName: 'CollapseMenu' }}
					onClick={openPanel}
					className={classes.iconButton}
				/>
			</Stack>
			<Panel
				isOpen={isOpen}
				onDismiss={dismissPanel}
				className={classes.panel}
				type={PanelType.smallFixedFar}
				isBlocking={false}
			>
				<ActionsPanelElements isPanel />
			</Panel>
		</>
	);
};

import React from 'react';
import { mergeStyles } from '@fluentui/react';

type Props = {
	className: string;
};

export const ContentWrapper: React.FC<Props> = (props) => {
	return (
		<div className={`${contentClass} ${props.className}`}>{props.children}</div>
	);
};

const contentClass = mergeStyles([
	{
		margin: '1rem auto 0 auto',
	},
]);

import React, { ReactNode } from 'react';
import {
	DetailsList as FluentDetailsList,
	IColumn,
	IDetailsListProps,
	IDetailsRowStyles,
	Stack,
} from '@fluentui/react';

export const DetailsList: React.FC<
	IDetailsListProps & {
		'data-testid': string;
		minRowHeight?: string;
		cellMargin?: string;
	}
> = ({ 'data-testid': dataTestId, minRowHeight, cellMargin, ...props }) => {
	const renderColumn = (
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		item?: any,
		_index?: number | undefined,
		column?: IColumn | undefined
	): ReactNode => {
		if (column?.fieldName === undefined) {
			return null;
		}
		if (props.columns === undefined) {
			return null;
		}
		return (
			<Stack verticalAlign="center" style={{ minHeight: '100%' }}>
				<Stack.Item data-testid={`column:${column.fieldName}`}>
					<span data-testid="device-list_device-id">
						{item[column.fieldName]}
					</span>
				</Stack.Item>
			</Stack>
		);
	};

	return (
		<div data-testid={dataTestId}>
			<FluentDetailsList
				onRenderRow={(props, defaultRender?): JSX.Element => {
					const customStyles: Partial<IDetailsRowStyles> = {};
					if (props) {
						if (minRowHeight) {
							customStyles.root = { minHeight: minRowHeight };
						}
						if (cellMargin) {
							customStyles.cell = {
								margin: cellMargin,
							};
						}

						props.styles = customStyles;
					}
					const itemId = props?.item['id'] ?? props?.itemIndex;
					return (
						<div data-testid={`row:${itemId}`}>
							{defaultRender && defaultRender(props)}
						</div>
					);
				}}
				onRenderItemColumn={renderColumn}
				{...props}
			/>
		</div>
	);
};

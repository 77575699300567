import React, { createContext, useContext } from 'react';
import axios, { AxiosInstance } from 'axios';
import useAuth from './auth';
import { useConfigContext } from '../config/configContext';
import { loadFromLocalStorage } from './localStorageHelper';
import { currentTenantLocalStorageKey } from '../support/constants';
import { useUserContext } from './userContext';

const defaultAxiosInstance = axios.create({ baseURL: 'not-loaded' });

export const apiContext = createContext<AxiosInstance>(defaultAxiosInstance);

export const useApiContext = (): AxiosInstance => useContext(apiContext);

const ApiProvider: React.FC = (props) => {
	const { config } = useConfigContext();
	const { userInfo } = useUserContext();
	const { initialized, keycloak } = useAuth();
	const apiInstance = axios.create({ baseURL: config.baseUrl + '/api' });

	if (initialized && keycloak && keycloak.token) {
		apiInstance.interceptors.request.use((config) => {
			if (!config.headers) {
				config.headers = {};
			}
			config.headers.Authorization = `Bearer ${keycloak.token}`;
			const localStorageValue = loadFromLocalStorage(
				`${currentTenantLocalStorageKey}-${userInfo.preferred_username}`
			);
			if (localStorageValue) {
				config.headers.RequestedTenant = localStorageValue;
			}
			return config;
		});
	}

	return (
		<apiContext.Provider value={apiInstance}>
			{props.children}
		</apiContext.Provider>
	);
};

export default ApiProvider;

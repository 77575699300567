export const filterEntities: <TEntity>(
	filter: string,
	filterKeys: (keyof TEntity)[],
	entities: TEntity[]
) => TEntity[] = (filter, filterKeys, entities) => {
	const filterStrings = filter
		.trim()
		.split(' ')
		.map((s: string) => s.trim().toLowerCase());
	const filteredEntities = entities.filter((entity) => {
		const filterValues = filterKeys.map((key) =>
			String(entity[key])?.toString().toLowerCase()
		);
		return filterStrings.every((searchedPart: string) =>
			filterValues.some((filterValue) => filterValue?.includes(searchedPart))
		);
	});
	return filteredEntities;
};

import * as React from 'react';
import { useIntl } from '@ysoft/react-intl';
import { Layer, Stack, mergeStyleSets } from '@fluentui/react';
import { ActionsPanel } from './ActionsPanel';
import { omniTheme } from '../../theme';

const classNames = mergeStyleSets({
	heading: {
		margin: 0,
		heading: 0,
	},
});

const headerStyle = {
	root: {
		padding: '0.3rem 1rem',
		background: omniTheme.palette.themePrimary,
		color: omniTheme.palette.white,
	},
};

export const Header: React.FC = () => {
	const { t } = useIntl();

	return (
		<Layer>
			<Stack
				horizontal
				styles={headerStyle}
				data-testid="layout-header"
				horizontalAlign="space-between"
				verticalAlign="center"
			>
				<Stack.Item>
					<h1 className={classNames.heading}>{t('app__title')}</h1>
				</Stack.Item>
				<Stack.Item>
					<Stack horizontal verticalAlign="center">
						<ActionsPanel />
					</Stack>
				</Stack.Item>
			</Stack>
		</Layer>
	);
};

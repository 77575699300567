import React from 'react';
import { mergeStyleSets } from '@fluentui/react';

const classes = mergeStyleSets({
	modalScrollableContent: {
		maxHeight: 'calc(min(40rem, 100vh - 20rem))',
		minHeight: '5rem',
		overflowY: 'auto',
		paddingBlockEnd: '2px',
	},
});

const ModalScrollableContent: React.FC<
	React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = (props) => {
	return (
		<div className={classes.modalScrollableContent} data-is-scrollable="true">
			{props.children}
		</div>
	);
};

export default ModalScrollableContent;

import React from 'react';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { DefaultButton, Stack } from '@fluentui/react';
import { TextField } from '../../../general-components/TextField';
import { useIntl } from '@ysoft/react-intl';
import ModalHeader from '../../../general-components/modals/ModalHeader';
import useRequestHelpForm from './useRequestHelpForm';
import DevicesDropdown from './DevicesDropdown';
import ProgressButton from '../../../general-components/ProgressButton';
import { ErrorMessageBar } from '../../../general-components/ErrorMessageBar';
import { childrenGap16 } from '../../../support/constants';
import { SuccessImageStackItem } from '../../../general-components/SuccessImageStackItem';
import { validate as validateEmail } from 'email-validator';

type RequestHelpProps = {
	onDismiss: () => void;
};

const RequestHelpForm: React.FC<RequestHelpProps> = (props) => {
	const { t } = useIntl();
	const { data, classes, operations } = useRequestHelpForm();

	const heading: JSX.Element = (
		<>
			<ModalHeader
				title={t('request-help__modal__header')}
				onDismiss={props.onDismiss}
			/>
			{data.sendingTicketState.value !== 'success' && (
				<>
					<p className={classes.introduction}>
						{t('request-help__modal__introduction')}
					</p>
					<p>{t('request-help__modal__write-in-english')}</p>
				</>
			)}
		</>
	);

	const inputs: JSX.Element = (
		<Stack tokens={childrenGap16}>
			<TextField
				data-testid="request-help__email-input"
				label={t('general__email')}
				control={operations.formControl}
				name="email"
				rules={{
					required: t('request-help__invalid-email'),
					validate: (email) =>
						validateEmail(email) || t('request-help__invalid-email'),
				}}
				backendValidations={{
					errors: data.backendValidations,
					errorMessage: t('request-help__invalid-email'),
				}}
				onChange={operations.onChangeEmail}
			/>
			<DevicesDropdown
				selectedKey={data.selectedRequestHelpWith}
				generalRequestKey={data.generalRequestOptionKey}
				onChange={operations.onChangeRequestHelpWith}
			/>
			<TextField
				data-testid="request-help__description"
				multiline
				rows={7}
				style={{ maxHeight: '20rem' }}
				placeholder={t('request-help__modal__support-request')}
				label={t('request-help__modal__support-request')}
				control={operations.formControl}
				name="description"
				rules={{
					required: t('request-help__description-is-empty'),
					maxLength: {
						value: data.maxSupportTicketTextLength,
						message: t('request-help__description-too-long', {
							maximumNumberOfCharacters: data.maxSupportTicketTextLength,
						}),
					},
				}}
				backendValidations={{
					errors: data.backendValidations,
					errorMessage: t('request-help__invalid-description', {
						maximumNumberOfCharacters: data.maxSupportTicketTextLength,
					}),
				}}
				onChange={operations.onChangeDescription}
			/>
			{data.sendingTicketState.error && (
				<ErrorMessageBar error={data.sendingTicketState.error} />
			)}
		</Stack>
	);

	const success: JSX.Element = (
		<Stack tokens={childrenGap16} horizontalAlign="center">
			<SuccessImageStackItem />
			<Stack.Item className={classes.successHeadingStackItem}>
				{t('request-help__modal__success-heading')}
			</Stack.Item>
			<Stack.Item className={classes.successInformationStackItem}>
				{t('request-help__modal__success-information-1')}
			</Stack.Item>
			<Stack.Item className={classes.successInformationStackItem}>
				{t('request-help__modal__success-information-2')}
			</Stack.Item>
		</Stack>
	);

	const actions: JSX.Element = (
		<Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 8 }}>
			<DefaultButton data-testid="cancel" onClick={props.onDismiss}>
				{t(
					`general__button-${
						data.sendingTicketState.value === 'success' ? 'close' : 'cancel'
					}`
				)}
			</DefaultButton>
			{data.sendingTicketState.value === 'inProgress' && (
				<ProgressButton>
					{t('request-help__modal__sending-request')}
				</ProgressButton>
			)}
			{data.sendingTicketState.value === 'idle' && (
				<PrimaryButton
					data-testid="request-help__send-request-button"
					type="submit"
				>
					{t('request-help__modal__send-request')}
				</PrimaryButton>
			)}
		</Stack>
	);

	return (
		<Stack className={classes.content} data-testid="request-help__modal-window">
			{heading}
			<form onSubmit={operations.handleSubmit(operations.onSubmitHandler)}>
				<Stack tokens={childrenGap16}>
					{data.sendingTicketState.value === 'success' ? success : inputs}
					{actions}
				</Stack>
			</form>
		</Stack>
	);
};

export default RequestHelpForm;

import React from 'react';
import {
	ISearchBoxProps,
	mergeStyleSets,
	SearchBox as FluentSearchBox,
} from '@fluentui/react';
import { useIntl } from '@ysoft/react-intl';

const classes = mergeStyleSets({
	searchBox: {
		width: '50%',
	},
});

export const SearchBox: React.FC<
	ISearchBoxProps & {
		'data-testid': string;
	}
> = ({ 'data-testid': dataTestId, ...props }) => {
	const { t } = useIntl();
	return (
		<FluentSearchBox
			data-testid={dataTestId}
			className={classes.searchBox}
			placeholder={t('general__search')}
			{...props}
		/>
	);
};

import { Shimmer, Stack } from '@fluentui/react';

export const generateLoading = (
	childrenGapSize = 10,
	arrayLength = 20
): JSX.Element => {
	return (
		<Stack data-testid="loading" tokens={{ childrenGap: childrenGapSize }}>
			{[...Array(arrayLength)].map((value, index) => (
				<Shimmer key={`shimmer-${index}`} />
			))}
		</Stack>
	);
};

import Keycloak from 'keycloak-js';
import {
	loadFromLocalStorage,
	loadFromLocalStorageOrSaveDefault,
	saveToLocalStorage,
} from '../libs/localStorageHelper';

const localeKey = 'omniportal.locale';
const licenseToggleKey = 'omniportal.licenseToggle';

export const isAuthMocked = process.env.REACT_APP_USE_KEYCLOAK_MOCK === 'true';

export interface Config {
	baseUrl?: string;
	dateFormatOptions: Intl.DateTimeFormatOptions;
	fallbackLocale: string;
	keycloak: Keycloak.KeycloakConfig;
	locale: string;
	licenseToggle: boolean;
}

const dateFormatOptions: Intl.DateTimeFormatOptions = {
	year: 'numeric',
	month: 'long',
	day: 'numeric',
};

export const defaultConfig: Config = {
	baseUrl: process.env.REACT_APP_BASE_URL,
	dateFormatOptions,
	get locale(): string {
		return loadFromLocalStorageOrSaveDefault(localeKey, 'en-US');
	},
	set locale(value: string) {
		saveToLocalStorage(localeKey, value);
	},
	get fallbackLocale(): string {
		return 'en-US';
	},
	keycloak: {
		url: process.env.REACT_APP_KEYCLOAK_URL,
		realm: process.env.REACT_APP_KEYCLOAK_REALM || '',
		clientId: process.env.REACT_APP_KEYCLOAK_CLIENT || '',
	},
	get licenseToggle(): boolean {
		return (
			(loadFromLocalStorage(licenseToggleKey) ||
				process.env.REACT_APP_LICENSE_TOGGLE ||
				'false') === 'true'
		);
	},
	set licenseToggle(value: boolean) {
		saveToLocalStorage(licenseToggleKey, value.toString());
	},
};

export const dynamicConfigUrl =
	'/config.json?' + new Date().getTime().toString();

import React, { useState } from 'react';
import { useIntl } from '@ysoft/react-intl';
import ProgressButton from '../../../general-components/ProgressButton';
import { DefaultButton, PrimaryButton, Stack } from '@fluentui/react';
import { useApiContext } from '../../../libs/apiContext';
import ModalHeader from '../../../general-components/modals/ModalHeader';
import { ErrorMessageBar } from '../../../general-components/ErrorMessageBar';
import ReactMarkdown from 'react-markdown';
import { SuccessImageStackItem } from '../../../general-components/SuccessImageStackItem';
import { SuccessCongratulationStackItem } from '../../../general-components/SuccessCongratulationStackItem';
import { ModalContentStack } from '../../../general-components/modals/ModalContentStack';
import { SwrMutate } from '../../../../types/swrMutate';

export type LicensesUnassignmentProps = {
	license: Api.License | undefined;
	onDismiss: () => void;
	mutateLicenses: SwrMutate<Api.License[]>;
};

const LicensesUnassignmentModal: React.FC<LicensesUnassignmentProps> = (
	props
) => {
	const { t } = useIntl();
	const api = useApiContext();

	const [unassigningLicenseState, setUnassigningLicenseState] = useState<{
		value: 'idle' | 'inProgress' | 'success';
		error?: Api.ErrorResponse;
	}>({ value: 'idle' });

	const unassignLicense = (license: Api.License | undefined) => {
		setUnassigningLicenseState({ value: 'inProgress' });
		api
			.delete(`/licenses/${license?.id}`)
			.then(() => {
				setUnassigningLicenseState({ value: 'success' });
				props
					.mutateLicenses(
						(prevLicenses) =>
							prevLicenses?.map((prevLicense) =>
								prevLicense.id === props.license?.id
									? {
											...prevLicense,
											assignedToDeviceId: undefined,
									  }
									: prevLicense
							),
						false
					)
					.then(() => {
						if (props.license) {
							api
								.get<Api.License>(`/licenses/${props.license.id}`)
								.then((response) => {
									props.mutateLicenses(
										(prevLicenses) =>
											prevLicenses?.map((prevLicense) =>
												prevLicense.id === props.license?.id
													? response.data
													: prevLicense
											),
										false
									);
								})
								.catch(() => {
									console.warn(
										'The new license data fetch failed. The UI state is rerendered, but not validated with the API.'
									);
								});
						}
					});
			})
			.catch((err) => {
				setUnassigningLicenseState({
					value: 'idle',
					error: err.response ? err.response : err,
				});
			});
	};

	const renderConfirmationStep = () => {
		return (
			<>
				<ReactMarkdown>
					{t('unassign-license-modal__are-you-sure', {
						licenseId: props.license?.id,
						deviceId: props.license?.assignedToDeviceId,
					})}
				</ReactMarkdown>

				{unassigningLicenseState.error && (
					<ErrorMessageBar error={unassigningLicenseState.error} />
				)}

				<Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="end">
					<DefaultButton
						data-testid="unassign-license-modal_cancel-button"
						text={t('general__button-cancel')}
						onClick={props.onDismiss}
					/>
					{unassigningLicenseState.value === 'inProgress' ? (
						<ProgressButton text={t('licenses-list__button-unassigning')} />
					) : (
						<PrimaryButton
							data-testid="unassign-license-modal-unassign-button"
							text={t('licenses-list__button-unassign')}
							onClick={() => {
								unassignLicense(props.license);
							}}
						/>
					)}
				</Stack>
			</>
		);
	};

	const renderSuccessStep = () => {
		return (
			<>
				<Stack tokens={{ childrenGap: 20 }} horizontalAlign="center">
					<SuccessImageStackItem />
					<SuccessCongratulationStackItem>
						{t('unassign-license-modal__success-congratulations')}
					</SuccessCongratulationStackItem>
					{props.license?.id && props.license.assignedToDeviceId && (
						<Stack.Item data-testid="unassign-modal_summary-text">
							<ReactMarkdown>
								{t('unassign-license-modal__success-license-device-info', {
									licenseId: props.license?.id,
									deviceId: props.license?.assignedToDeviceId,
								})}
							</ReactMarkdown>
						</Stack.Item>
					)}
					<Stack.Item>
						{t('unassign-license-modal__success-information')}
					</Stack.Item>
				</Stack>
				<Stack horizontalAlign="end">
					<DefaultButton
						data-testid="omni-close"
						text={t('general__button-close')}
						onClick={props.onDismiss}
					/>
				</Stack>
			</>
		);
	};

	return (
		<ModalContentStack data-testid="unassign-license-modal">
			<ModalHeader
				title={t('unassign-license-modal__heading')}
				onDismiss={props.onDismiss}
			/>

			{unassigningLicenseState.value === 'success'
				? renderSuccessStep()
				: renderConfirmationStep()}
		</ModalContentStack>
	);
};

export default LicensesUnassignmentModal;

import React, { useState } from 'react';
import {
	IDropdownOption,
	Dropdown,
	SearchBox,
	Stack,
	StackItem,
	ISelectableOption,
	IDropdownStyles,
	mergeStyleSets,
} from '@fluentui/react';
import { useIntl } from '@ysoft/react-intl';
import useDebounce from '../hooks/useDebounce';

type HeadingWithSearchForDevicesProps = {
	'data-testid': string;
	headingTitle: string;
	searchBoxPlaceholder?: string;
	searchedTerm: string;
	setSearchedTerm: (searchTerm: string) => void;
	deviceStatus: Api.DeviceStatus;
	setDeviceStatus: (deviceStatus: Api.DeviceStatus) => void;
};

const setSearchTermDelay = 300;

const classes = mergeStyleSets({
	searchBox: {
		width: '15rem',
	},
});

const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 140 } };

const HeadingWithSearchForDevices: React.FC<
	HeadingWithSearchForDevicesProps
> = (props) => {
	const { t } = useIntl();

	const [tempSearchedTerm, setTempSearchedTerm] = useState<string>(
		props.searchedTerm
	);

	useDebounce(
		() => props.setSearchedTerm(tempSearchedTerm),
		setSearchTermDelay,
		[tempSearchedTerm]
	);

	const deviceStatusOptions: IDropdownOption[] = [
		{
			key: 'All',
			text: t('omni-bridge-devices-list__status_all'),
		},
		{
			key: 'NotConfigured',
			text: t('omni-bridge-devices-list__status_not_configured'),
		},
		{
			key: 'Configured',
			text: t('omni-bridge-devices-list__status_configured'),
		},
	];

	const onRenderOption = (
		option: ISelectableOption | undefined
	): JSX.Element => {
		return <span data-testid="option">{option?.text}</span>;
	};

	const selectedKey =
		deviceStatusOptions.find((i) => i.key === props.deviceStatus)?.key || 0;

	return (
		<Stack
			data-testid={props['data-testid']}
			wrap
			horizontal
			tokens={{ childrenGap: 10 }}
		>
			<StackItem>
				<h1 data-testid="title">{props.headingTitle}</h1>
			</StackItem>
			<StackItem align="center">
				<SearchBox
					data-testid="search-box"
					className={classes.searchBox}
					placeholder={props.searchBoxPlaceholder || t('general__search')}
					defaultValue={props.searchedTerm}
					onClear={() => props.setSearchedTerm('')}
					onChange={(_, term) => setTempSearchedTerm(term || '')}
					onSearch={(term) => props.setSearchedTerm(term)}
				/>
			</StackItem>
			<StackItem align="center">
				<Dropdown
					onChange={(_, o) => {
						const val = o?.key.toString() as string;
						const deviceStatus =
							'Configured' === val || 'NotConfigured' === val || 'All' === val
								? val
								: undefined;
						props.setDeviceStatus(deviceStatus);
					}}
					data-testid="search-device-status-dropdown"
					placeholder={t('omni-bridge-devices-list__status_placeholder')}
					options={deviceStatusOptions}
					selectedKey={selectedKey}
					onRenderOption={onRenderOption}
					styles={dropdownStyles}
				/>
			</StackItem>
		</Stack>
	);
};

export default HeadingWithSearchForDevices;

import { Theme } from '@fluentui/react';
import { createTheme } from '@fluentui/react/lib/Styling';

export const omniTheme: Theme = createTheme({
	palette: {
		themePrimary: '#008299',
		themeLighterAlt: '#f1f9fb',
		themeLighter: '#c8e9ef',
		themeLight: '#9dd6e0',
		themeTertiary: '#4eb0c2',
		themeSecondary: '#148fa5',
		themeDarkAlt: '#00758a',
		themeDark: '#006374',
		themeDarker: '#004956',
		neutralLighterAlt: '#faf9f8',
		neutralLighter: '#f3f2f1',
		neutralLight: '#edebe9',
		neutralQuaternaryAlt: '#e1dfdd',
		neutralQuaternary: '#d0d0d0',
		neutralTertiaryAlt: '#c8c6c4',
		neutralTertiary: '#a19f9d',
		neutralSecondary: '#605e5c',
		neutralPrimaryAlt: '#3b3a39',
		neutralPrimary: '#323130',
		neutralDark: '#201f1e',
		black: '#000000',
		white: '#ffffff',
	},
});

import React, { useContext, useState, useEffect } from 'react';
import useAuth from './auth';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { mergeStyleSets, Stack } from '@fluentui/react';
import { useIntl } from '@ysoft/react-intl';

const classNames = mergeStyleSets({
	page_loading_spinner: {
		'margin-top': '10rem',
	},
});

type UserContextData = {
	userInfo: Api.UserInfo;
	isPartner: boolean;
	hasTenantId: boolean;
	isPartnerPortalUserOnlyAndWithoutPartnerRole: boolean;
};

const defaultUserData: UserContextData = {
	userInfo: {
		preferred_username: 'Unknown User',
	},
	isPartner: false,
	hasTenantId: false,
	isPartnerPortalUserOnlyAndWithoutPartnerRole: false,
};

const userContext = React.createContext<UserContextData>(defaultUserData);

export const useUserContext = (): UserContextData => useContext(userContext);

const UserContextProvider: React.FC = (props) => {
	const { keycloak } = useAuth();
	const [userData, setUserData] = useState(defaultUserData);
	const { t } = useIntl();

	const keycloakAuthenticated = keycloak?.authenticated;

	useEffect(() => {
		if (keycloakAuthenticated) {
			keycloak
				?.loadUserInfo()
				.then((result) => {
					setUserData((previousUserData) => {
						return { ...previousUserData, userInfo: result as Api.UserInfo };
					});
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, [keycloak, keycloakAuthenticated]);

	if (userData.userInfo === defaultUserData.userInfo) {
		return (
			<Stack
				data-testid="port-spinner"
				horizontalAlign="center"
				verticalAlign="center"
				className={classNames.page_loading_spinner}
			>
				<Spinner
					size={SpinnerSize.large}
					label={t('port-configuration__page-loading-spinner-label')}
				/>
			</Stack>
		);
	}
	const userInfo = userData.userInfo;
	const hasTenantId = !!userInfo.tenant_id;
	const isPartner =
		!!userInfo.partnerId &&
		userInfo.roles &&
		userInfo.roles.includes('PartnerSafeQEdgePortal');
	const isPartnerPortalUserOnlyAndWithoutPartnerRole = !!(
		!hasTenantId &&
		userInfo.partnerId &&
		!userInfo.roles?.includes('PartnerSafeQEdgePortal')
	);

	return (
		<userContext.Provider
			value={{
				userInfo,
				hasTenantId,
				isPartner: isPartner ?? false,
				isPartnerPortalUserOnlyAndWithoutPartnerRole:
					isPartnerPortalUserOnlyAndWithoutPartnerRole ?? false,
			}}
		>
			{props.children}
		</userContext.Provider>
	);
};

export default UserContextProvider;

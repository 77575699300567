import {
	IObjectWithKey,
	ISelection,
	Selection,
	SelectionMode,
} from '@fluentui/react';
import { generateLoading } from '../../libs/generateLoading';
import { DetailsList } from '../../general-components/DetailsList';
import React, { useState } from 'react';
import { useIntl } from '@ysoft/react-intl';
import ModalScrollableContent from '../../general-components/modals/ModalScrollableContent';
import useInfiniteScrolling from '../../hooks/useInfiniteScrolling';
import { isLicenseExpired } from '../../libs/licensesHelpers';

export type DevicePickerProps = {
	setSelectedDeviceId?: (deviceId: string) => void;
	currentDeviceId?: string;
	searchedTerm?: string;
	licenseInfo?: {
		filterValidLicenseOnly: boolean;
		allLicenses: Api.License[] | undefined;
	};
};

export interface ColumnModel {
	deviceId: string;
}

const unselectedDeviceIdValue = '';

const DevicePicker: React.FC<DevicePickerProps> = (props) => {
	const { t } = useIntl();
	const [selectedDeviceId, setSelectedDeviceId] = useState(
		unselectedDeviceIdValue
	);

	const detailsListColumns = [
		{
			key: 'deviceId',
			name: t('assign-license-modal__devices-list-device-id'),
			fieldName: 'deviceId',
			minWidth: 100,
			isResizable: false,
		},
	];

	const { swrData, loadMoreTriggerRef, hasReachedEnd } =
		useInfiniteScrolling<Api.DevicesList>(props.searchedTerm ?? '', '/devices');

	const devices = swrData.data
		? ([] as Api.Device[]).concat(
				swrData.data.map((devicesResponse) => devicesResponse.items).flat()
		  )
		: [];

	let columnItems: ColumnModel[] = devices
		.map((device) => ({ deviceId: device.deviceId } as ColumnModel))
		.filter((item) => item.deviceId !== props.currentDeviceId);

	if (props.licenseInfo?.filterValidLicenseOnly) {
		columnItems = columnItems.filter((item) =>
			props.licenseInfo?.allLicenses?.some(
				(license) =>
					!isLicenseExpired(license) &&
					license.assignedToDeviceId === item.deviceId
			)
		);
	}

	const selectDeviceId = (deviceId?: string) => {
		const selectedDeviceId = deviceId ? deviceId : unselectedDeviceIdValue;
		setSelectedDeviceId(selectedDeviceId);
		if (props.setSelectedDeviceId) {
			props.setSelectedDeviceId(selectedDeviceId);
		}
	};

	const deselectDeviceId = () => {
		selectDeviceId(unselectedDeviceIdValue);
	};

	const selection = new Selection({
		onSelectionChanged: () => {
			const selectedDeviceId = (selection.getSelection()[0] as Api.Device)
				?.deviceId;
			selectDeviceId(selectedDeviceId);
		},
		getKey: (columnModel: ColumnModel) => {
			return columnModel.deviceId;
		},
		items: columnItems,
	});

	if (
		selectedDeviceId &&
		!columnItems.find((device) => device.deviceId === selectedDeviceId)
	) {
		deselectDeviceId();
	}

	return (
		<ModalScrollableContent>
			{swrData.error ? (
				<div data-testid="error">{t('error__general')}</div>
			) : (
				<>
					{columnItems.length === 0 ? (
						<>
							{swrData.isValidating ? (
								generateLoading()
							) : props.licenseInfo?.filterValidLicenseOnly ? (
								<p data-testid="migrate-modal_no-licensed-device">
									{t('migrate-from__modal__no-licensed-device')}
								</p>
							) : (
								<p data-testid="migrate-modal_no-device-found">
									{t('omni-bridge-devices-list__empty-list')}
								</p>
							)}
						</>
					) : (
						<>
							<DetailsList
								data-testid="devices-list"
								items={columnItems}
								columns={detailsListColumns}
								setKey="banana"
								selection={selection as unknown as ISelection<IObjectWithKey>}
								selectionMode={SelectionMode.single}
							/>
							{swrData.isValidating && generateLoading()}
						</>
					)}
					{!hasReachedEnd && <div ref={loadMoreTriggerRef} />}
				</>
			)}
		</ModalScrollableContent>
	);
};

export default DevicePicker;

import React, { useState } from 'react';
import { DefaultButton, IContextualMenuProps, Stack } from '@fluentui/react';
import useConfigurationValidation from './useConfigurationValidation';
import { NotificationBar } from '../../general-components/NotificationBar';
import ProgressButton from '../../general-components/ProgressButton';
import LoadAnotherDeviceConfigurationModal from './LoadAnotherDeviceConfigurationModal';
import MigrateAnotherDeviceModal from './MigrateAnotherDeviceModal';
import { useIntl } from '@ysoft/react-intl';
import { NotificationData } from './Configuration';
import { SwrMutate } from '../../../types/swrMutate';

export type ConfigurationButtonBarProps = {
	deviceId: string;
	apply: () => void;
	back: () => void;
	license: Api.License | undefined | Record<string, never>;
	mutateValidLicense: SwrMutate<Api.License | Record<string, never>>;
	reloadPortConfigurationForDevice: (
		deviceId: string,
		isMigration: boolean
	) => void;
	canEditConfiguration: () => boolean;
	isLoadingConfiguration: boolean;
	shownData: string;
	sendingData: boolean;
	dataChanged: boolean;
	notificationVisible: boolean;
	setNotificationVisible: React.Dispatch<React.SetStateAction<boolean>>;
	notificationData: NotificationData;
};

const ConfigurationButtonBar: React.FC<ConfigurationButtonBarProps> = (
	props
) => {
	const { validations } = useConfigurationValidation();
	const { t } = useIntl();
	const [isCopyConfigFromModalOpen, setCopyConfigFromModalOpen] =
		useState(false);
	const [isMigrateDeviceModalOpen, setMigrateDeviceModalOpen] = useState(false);

	const menuProps: IContextualMenuProps = {
		items: [
			{
				key: 'loadConfiguration',
				text: t('port-configuration__load-configuration-button'),
				disabled: !props.canEditConfiguration(),
				'data-testid': 'port-configuration__load-configuration-button',
				onClick: () => setCopyConfigFromModalOpen(true),
			},
			{
				key: 'migrateDevice',
				text: t('port-configuration__migrate-button'),
				disabled: props.isLoadingConfiguration,
				'data-testid': 'port-configuration__migrate-button',
				onClick: () => setMigrateDeviceModalOpen(true),
			},
		],
	};

	const isValidConfiguration = (
		value: string,
		license: Api.License | undefined | Record<string, never>
	): boolean => {
		return (
			validations.validateJSON(value) === '' &&
			validations.validateOpenVpnConfiguration(value) === '' &&
			validations.validateGatewayConfiguration(license, value) === '' &&
			validations.validateDnsServerConfiguration(value) === '' &&
			validations.validateDhcpConfiguration(license, value) === '' &&
			validations.validatePortForwardingRules(value) === ''
		);
	};

	const renderNotificationBar = () => {
		return (
			<NotificationBar
				data-testid={'port-configuration__notification-bar'}
				text={props.notificationData.content}
				isVisible={props.notificationVisible}
				notificationType={props.notificationData.notificationType}
				onDismiss={() => {
					props.setNotificationVisible(false);
				}}
				dismissButtonAriaLabel="Close"
			/>
		);
	};

	return (
		<>
			<LoadAnotherDeviceConfigurationModal
				isOpen={isCopyConfigFromModalOpen}
				onDismiss={() => setCopyConfigFromModalOpen(false)}
				setSelectedDeviceId={(selectedDeviceId) => {
					props.reloadPortConfigurationForDevice(selectedDeviceId, true);
				}}
				currentDeviceId={props.deviceId}
			/>
			<MigrateAnotherDeviceModal
				isOpen={isMigrateDeviceModalOpen}
				onDismiss={() => setMigrateDeviceModalOpen(false)}
				setSelectedDeviceId={(_) => {
					props.reloadPortConfigurationForDevice(props.deviceId, false);
				}}
				currentDeviceId={props.deviceId}
				mutateValidLicense={props.mutateValidLicense}
			/>
			<Stack horizontal horizontalAlign="end" tokens={{ childrenGap: '1rem' }}>
				<Stack.Item grow>{renderNotificationBar()}</Stack.Item>
				<Stack.Item>
					<DefaultButton
						data-testid={'port-configuration__cancel-button'}
						text={t('port-configuration__back-button')}
						onClick={() => props.back()}
					/>
				</Stack.Item>
				<Stack.Item>
					{props.isLoadingConfiguration ? (
						<ProgressButton>
							{t('port-configuration__actions-button')}
						</ProgressButton>
					) : (
						<DefaultButton
							data-testid="port-configuration__actions-button"
							text={t('port-configuration__actions-button')}
							menuProps={menuProps}
						/>
					)}
				</Stack.Item>
				<Stack.Item>
					{props.sendingData ? (
						<ProgressButton>
							{t('port-configuration__apply-button-processing')}
						</ProgressButton>
					) : (
						<DefaultButton
							data-testid="port-configuration__apply-button"
							text={t('port-configuration__apply-button')}
							onClick={() => props.apply()}
							disabled={
								!(
									props.dataChanged &&
									isValidConfiguration(props.shownData, props.license) &&
									props.canEditConfiguration()
								)
							}
						/>
					)}
				</Stack.Item>
			</Stack>
		</>
	);
};

export default ConfigurationButtonBar;

import React, { ComponentProps } from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import remarkDirective from 'remark-directive';
import { visit, VisitorResult } from 'unist-util-visit';
import Admonition from './markdown/Admonition';

type VisitParams = Parameters<typeof visit>;
type Tree = VisitParams[0] & { [key: string]: unknown };
type OMNIExtendedReactMarkdownType = ComponentProps<typeof ReactMarkdown> & {
	components: {
		admonition: typeof Admonition;
	};
};

const OMNIExtendedReactMarkdown: React.FC<OMNIExtendedReactMarkdownType> = (
	props
) => {
	return <ReactMarkdown {...props} />;
};

const Markdown: React.FC<{ content: string }> = (props) => {
	const remarkDirectiveVisitor = () => {
		return (tree: Tree) => {
			visit<Tree, string[]>(
				tree,
				['textDirective', 'leafDirective', 'containerDirective'],
				(node: Tree) => {
					node.data = {
						hName: node.name,
						hProperties: node.attributes,
						...node,
					};
					return node as unknown as VisitorResult;
				}
			);
		};
	};

	return (
		<OMNIExtendedReactMarkdown
			remarkPlugins={[gfm, remarkDirective, remarkDirectiveVisitor]}
			components={{
				admonition: Admonition,
			}}
		>
			{props.content}
		</OMNIExtendedReactMarkdown>
	);
};

export default Markdown;

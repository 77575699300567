import React, { useState } from 'react';
import { IDropdownOption } from '@fluentui/react';
import SearchableDropdown from '../../../general-components/SearchableDropdown';
import { useIntl } from '@ysoft/react-intl';
import useSearchedTerm from '../../../hooks/useSearchedTerm';
import useInfiniteScrolling from '../../../hooks/useInfiniteScrolling';
import useDebounce from '../../../hooks/useDebounce';

const DevicesDropdown: React.FC<{
	onChange: (
		e: React.FormEvent<HTMLDivElement>,
		option?: IDropdownOption
	) => void;
	selectedKey: string;
	generalRequestKey: string;
}> = (props) => {
	const { t } = useIntl();

	const { searchedTerm, setSearchedTerm } =
		useSearchedTerm('q-devices-dropdown');

	const automaticSearchDelay = 300;
	const [tempSearchedTerm, setTempSearchedTerm] = useState('');
	useDebounce(() => setSearchedTerm(tempSearchedTerm), automaticSearchDelay, [
		tempSearchedTerm,
	]);

	const {
		swrData: devicesResponse,
		loadMoreTriggerRef,
		hasReachedEnd,
	} = useInfiniteScrolling<Api.DevicesList>(searchedTerm, '/devices');

	const devices = devicesResponse.data
		? devicesResponse.data.map((response) => response.items).flat()
		: [];

	const options: IDropdownOption[] = [
		{
			key: props.generalRequestKey,
			text: t('request-help__general-request'),
		},
	].concat(
		devices.map((device) => ({
			key: device.deviceId,
			text: device.deviceId,
		}))
	);

	return (
		<SearchableDropdown
			data-testid="select-device"
			label={t('request-help__modal__dropdown-label')}
			options={options}
			onChange={props.onChange}
			selectedKey={[props.selectedKey]}
			infiniteScrolling={{
				loadMoreTriggerRef,
				isValidating: devicesResponse.isValidating,
				hasReachedEnd,
				searchedTerm,
				setSearchedTerm: setTempSearchedTerm,
			}}
			calloutProps={{ calloutMaxHeight: 300 }}
		/>
	);
};

export default DevicesDropdown;

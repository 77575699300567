import React from 'react';
import {
	Dropdown,
	DropdownMenuItemType,
	ISelectableOption,
	IDropdownProps,
	SearchBox,
	ISearchBoxProps,
	IDropdownOption,
} from '@fluentui/react';
import { generateLoading } from '../libs/generateLoading';
import { useIntl } from '@ysoft/react-intl';

const filterHeaderKey = 'FilterHeader';
const filterHeaderDividerKey = 'FilterHeaderDivider';
const loadingIndicatorsKey = 'LoadingIndicators';
const loadMoreTriggerFooterKey = 'LoadMoreTriggerFooter';
const noResultsFoundKey = 'NoResultsFound';

export type SearchableDropdownInfiniteScrollingInfo = {
	loadMoreTriggerRef: (node?: Element | null | undefined) => void;
	isValidating: boolean;
	hasReachedEnd: boolean;
	searchedTerm: string;
	setSearchedTerm: (newValue: string) => void;
};

const SearchableDropdown: React.FunctionComponent<
	IDropdownProps & {
		'data-testid': string;
		searchBoxProps?: ISearchBoxProps;
		noResultsFoundMessage?: string;
		infiniteScrolling?: SearchableDropdownInfiniteScrollingInfo;
	}
> = ({
	infiniteScrolling,
	noResultsFoundMessage,
	searchBoxProps,
	...props
}) => {
	const { t } = useIntl();

	const [searchText, setSearchText] = React.useState<string>('');
	const renderOption = (option: ISelectableOption | undefined): JSX.Element => {
		return option?.key === filterHeaderKey ? (
			<SearchBox
				data-testid="search"
				onChange={(e, newValue) => {
					const value = newValue ? newValue : '';
					if (infiniteScrolling) {
						infiniteScrolling.setSearchedTerm(value);
					} else {
						setSearchText(value);
					}
				}}
				underlined={true}
				placeholder={t('general__search')}
				defaultValue={
					infiniteScrolling ? infiniteScrolling.searchedTerm : searchText
				}
				{...searchBoxProps}
			/>
		) : option?.key === loadingIndicatorsKey ? (
			generateLoading(10, 2)
		) : option?.key === loadMoreTriggerFooterKey ? (
			<div
				ref={
					infiniteScrolling ? infiniteScrolling.loadMoreTriggerRef : undefined
				}
			/>
		) : (
			<span data-testid="option">{option?.text}</span>
		);
	};

	const allOptions: IDropdownOption[] = props.options.map((option) =>
		!option?.disabled &&
		(searchText == null ||
			option?.text?.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
			? option
			: { ...option, hidden: true }
	);
	const shownOptionsCount = allOptions.reduce(
		(sum, option) => (option.hidden ? sum : sum + 1),
		0
	);

	return (
		<Dropdown
			{...props}
			options={[
				{
					key: filterHeaderKey,
					text: '',
					itemType: DropdownMenuItemType.Header,
				},
				{
					key: filterHeaderDividerKey,
					text: '',
					itemType: DropdownMenuItemType.Divider,
				},
				...allOptions,
				...(shownOptionsCount === 0 &&
				(infiniteScrolling ? infiniteScrolling?.hasReachedEnd : true)
					? [
							{
								key: noResultsFoundKey,
								text: noResultsFoundMessage
									? noResultsFoundMessage
									: t('general__search-no-results-found'),
								itemType: DropdownMenuItemType.Header,
							},
					  ]
					: []),
				...(infiniteScrolling && !infiniteScrolling?.hasReachedEnd
					? [
							{
								key: infiniteScrolling?.isValidating
									? loadingIndicatorsKey
									: loadMoreTriggerFooterKey,
								text: '',
								itemType: DropdownMenuItemType.Header,
							},
					  ]
					: []),
			]}
			onRenderOption={renderOption}
			calloutProps={{ ...props.calloutProps, className: 'optionsClass' }}
		/>
	);
};

export default SearchableDropdown;

import React, { useCallback } from 'react';
import { IMessageBarProps, MessageBar, MessageBarType } from '@fluentui/react';
import { useIntl } from '@ysoft/react-intl';
import { getSpecificErrorMessageTranslationStringKey } from '../libs/getSpecificErrorMessageTranslationStringKey';

export const ErrorMessageBar: React.FC<
	IMessageBarProps & {
		error?: Api.ErrorResponse;
		errorMessage?: string;
	}
> = (props) => {
	const { t } = useIntl();

	const resolveErrorMessage = useCallback(
		(errorMessage: string | undefined) => {
			return errorMessage ? errorMessage : t('error__general');
		},
		[t]
	);

	return (
		<MessageBar
			data-testid="error-message-bar"
			messageBarType={MessageBarType.error}
			{...props}
		>
			{props.error?.data
				? t(getSpecificErrorMessageTranslationStringKey(props.error.data))
				: resolveErrorMessage(props.errorMessage)}
		</MessageBar>
	);
};

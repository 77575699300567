import React from 'react';
import { useIntl } from '@ysoft/react-intl';
import {
	DefaultButton,
	IObjectWithKey,
	ISelection,
	PrimaryButton,
	SelectionMode,
	Stack,
} from '@fluentui/react';
import { generateLoading } from '../../../libs/generateLoading';
import { DetailsList } from '../../../general-components/DetailsList';
import LicenseDetail from '../LicenseDetail';
import LicensesAssignmentSuccessStep from './LicensesAssignmentSuccessStep';
import LicensesAssignmentSummaryStep from './LicensesAssignmentSummaryStep';
import { ErrorMessageBar } from '../../../general-components/ErrorMessageBar';
import useLicensesAssignment, {
	LicensesAssignmentProps,
} from './useLicensesAssignment';
import ProgressButton from '../../../general-components/ProgressButton';
import ModalHeader from '../../../general-components/modals/ModalHeader';
import { ModalContentStack } from '../../../general-components/modals/ModalContentStack';
import ModalScrollableContent from '../../../general-components/modals/ModalScrollableContent';
import { SearchBox } from '../../../general-components/SearchBox';

const LicensesAssignment: React.FC<LicensesAssignmentProps> = (props) => {
	const { data, operations } = useLicensesAssignment(props);
	const { t } = useIntl();

	const selectDeviceStep = (
		<>
			<Stack>
				<LicenseDetail license={props.license} />
				<Stack
					horizontal
					verticalAlign="center"
					horizontalAlign="space-between"
				>
					<p>{t('assign-license-modal__select-info')}</p>
					<SearchBox
						data-testid="licenses-modal__search-bar"
						defaultValue={data.searchedTerm}
						onSearch={operations.setSearchedTerm}
						onClear={() => operations.setSearchedTerm('')}
					/>
				</Stack>
			</Stack>
			<ModalScrollableContent>
				{data.devices.error ? (
					<div data-testid="error">{t('error__general')}</div>
				) : (
					<>
						{data.devices.values.length === 0 ? (
							<>
								{data.devices.isValidating ? (
									generateLoading()
								) : (
									<p>{t('omni-bridge-devices-list__empty-list')}</p>
								)}
							</>
						) : (
							<>
								<DetailsList
									data-testid="devices-list"
									items={data.columnItems}
									columns={data.detailsListColumns}
									setKey="banana"
									selection={
										data.devices.selection
											.value as unknown as ISelection<IObjectWithKey>
									}
									selectionMode={SelectionMode.single}
								/>
								{data.devices.isValidating && generateLoading()}
							</>
						)}
						{!data.hasReachedEnd && <div ref={operations.loadMoreTriggerRef} />}
					</>
				)}
			</ModalScrollableContent>
			{data.assignLicense.state.error && (
				<ErrorMessageBar error={data.assignLicense.state.error} />
			)}

			<Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="end">
				<DefaultButton
					text={t('general__button-cancel')}
					onClick={props.onDismiss}
				/>
				{data.assignLicense.state.value === 'inProgress' ? (
					<ProgressButton
						text={
							!data.devices.selection.selectedDevice ||
							!operations.hasLicense(
								data.devices.selection.selectedDevice.deviceId
							)
								? t('licenses-list__button-assigning')
								: t('licenses-list__button-extending')
						}
					/>
				) : (
					<PrimaryButton
						data-testid="assign-license-modal-assign-button"
						disabled={
							!data.devices.selection.selectedDevice ||
							!data.columnItems.find(
								(device) =>
									device.deviceId ===
									data.devices.selection.selectedDevice?.deviceId
							)
						}
						text={
							!data.devices.selection.selectedDevice ||
							!operations.hasLicense(
								data.devices.selection.selectedDevice.deviceId
							)
								? t('licenses-list__button-assign')
								: t('licenses-list__button-extend')
						}
						onClick={() => operations.setAssignLicenseModalStep('summary')}
					/>
				)}
			</Stack>
		</>
	);

	return (
		<ModalContentStack data-testid="assign-license-modal">
			<ModalHeader
				title={t('assign-license-modal__heading')}
				onDismiss={props.onDismiss}
			/>
			{data.assignLicense.state.value === 'success' ? (
				<LicensesAssignmentSuccessStep
					onDismiss={props.onDismiss}
					license={props.license}
					device={data.devices.selection.selectedDevice}
				/>
			) : data.assignLicenseModalStep === 'select' ? (
				selectDeviceStep
			) : (
				data.assignLicenseModalStep === 'summary' && (
					<LicensesAssignmentSummaryStep
						license={props.license}
						selectedDevice={data.devices.selection.selectedDevice}
						assignLicenseState={data.assignLicense.state}
						assignLicense={operations.assignLicense}
						hasLicense={operations.hasLicense}
						getLicenseOfDevice={operations.getLicenseOfDevice}
						getExpirationForDevice={operations.getExpirationForDevice}
						onDismiss={props.onDismiss}
					/>
				)
			)}
		</ModalContentStack>
	);
};

export default LicensesAssignment;

import React from 'react';
import { useIntl } from '@ysoft/react-intl';
import {
	DefaultButton,
	PrimaryButton,
	Stack,
	mergeStyles,
	Icon,
	MessageBar,
	MessageBarType,
} from '@fluentui/react';
import LicenseDetail from '../LicenseDetail';
import { ErrorMessageBar } from '../../../general-components/ErrorMessageBar';
import ProgressButton from '../../../general-components/ProgressButton';
import { Badge } from '../../../general-components/Badge';
import { useConfigContext } from '../../../config/configContext';
import { isLicenseExpired } from '../../../libs/licensesHelpers';

const iconClass = mergeStyles({
	fontSize: '150%',
});

const informationClass = mergeStyles({
	marginLeft: '1em',
});

const modalSubheading = mergeStyles({
	fontWeight: 'bold',
});

export type LicensesAssignmentSummaryStepProps = {
	license: Api.License | undefined;
	selectedDevice: Api.Device | undefined;
	assignLicenseState: {
		value: 'inProgress' | 'idle' | 'success';
		error?: Api.ErrorResponse | undefined;
	};
	assignLicense: () => void;
	hasLicense: (deviceId: string) => boolean;
	getLicenseOfDevice: (deviceId: string) => Api.License | undefined;
	getExpirationForDevice: (deviceId: string) => string;
	onDismiss: () => void;
};

const LicensesAssignmentSummaryStep: React.FC<
	LicensesAssignmentSummaryStepProps
> = (props) => {
	const configContext = useConfigContext();
	const { t } = useIntl();

	const toConfiguredLocaleString = (date: Date) => {
		return date.toLocaleString(
			configContext.config.locale,
			configContext.config.dateFormatOptions
		);
	};

	const getActivationDate = (
		selectedDevice: Api.Device | undefined
	): string => {
		let activationDate = new Date();

		if (props.license?.activated) return props.license.activated;
		if (
			isLicenseExpired(props.getLicenseOfDevice(selectedDevice?.deviceId || ''))
		)
			return toConfiguredLocaleString(new Date());

		if (
			selectedDevice &&
			props.hasLicense(props.selectedDevice?.deviceId || '') &&
			props.getLicenseOfDevice(selectedDevice.deviceId)
		) {
			activationDate = new Date(
				props.getExpirationForDevice(selectedDevice.deviceId)
			);
			activationDate.setDate(activationDate.getDate() + 1);
		}

		return toConfiguredLocaleString(activationDate);
	};

	const getExpirationDate = (activationDate: string): string => {
		if (props.license?.activated) return props.license.expirationDate;

		const expirationDate = new Date(activationDate);
		expirationDate.setFullYear(expirationDate.getFullYear() + 1);
		expirationDate.setDate(expirationDate.getDate() - 1);

		return toConfiguredLocaleString(expirationDate);
	};

	const activationDate = getActivationDate(props.selectedDevice);
	return (
		<>
			<Stack tokens={{ childrenGap: 20 }}>
				<Stack.Item>
					<span className={modalSubheading}>
						{t('assign-license-modal__summary')}
					</span>
				</Stack.Item>
				<Stack.Item>
					<Stack tokens={{ childrenGap: 10 }}>
						<span>{t('assign-license-modal__selected-license')}</span>
						<div className={informationClass}>
							<LicenseDetail license={props.license} />
						</div>
					</Stack>
				</Stack.Item>
				<Stack.Item>
					<Stack tokens={{ childrenGap: 10 }}>
						<span>{t('assign-license-modal__selected-device')}</span>
						<Stack
							horizontal
							tokens={{ childrenGap: 10 }}
							className={informationClass}
						>
							<Icon iconName="ServerEnviroment" className={iconClass} />
							<span data-testid="assign-license-modal_device-info">
								{props.selectedDevice?.deviceId}
							</span>
						</Stack>
					</Stack>
				</Stack.Item>
				<Stack.Item>
					<Stack tokens={{ childrenGap: 10 }}>
						<span>{t('assign-license-modal__license-term')}</span>
						<Stack
							data-testid="assign-license-modal_license-time-info"
							horizontal
							tokens={{ childrenGap: 20 }}
							className={informationClass}
						>
							<Stack>
								<span>{t('licenses-list__activation-date')}</span>
								<Badge
									data-testid="license-summary_activation-date"
									tone="positive"
								>
									{activationDate}
								</Badge>
							</Stack>
							<Stack>
								<span>{t('licenses-list__expiration-date')}</span>
								<Badge
									data-testid="license-summary_expiration-date"
									tone="neutral"
								>
									{getExpirationDate(activationDate)}
								</Badge>
							</Stack>
						</Stack>
					</Stack>
				</Stack.Item>
				<MessageBar
					data-testid="license-summary_message-bar"
					messageBarType={MessageBarType.warning}
				>
					<Stack>
						<span>{t('assign-license-modal__license-will-be-activated')}</span>
						<span>
							<b>{t('assign-license-modal__cannot-be-undone')}</b>
						</span>
					</Stack>
				</MessageBar>
			</Stack>

			{props.assignLicenseState.error && (
				<ErrorMessageBar error={props.assignLicenseState.error} />
			)}
			<Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="end">
				<DefaultButton
					data-testid="assign-license-modal_cancel-button"
					text={t('general__button-cancel')}
					onClick={props.onDismiss}
				/>
				{props.assignLicenseState.value === 'inProgress' ? (
					<ProgressButton
						text={
							!props.selectedDevice ||
							!props.hasLicense(props.selectedDevice.deviceId)
								? t('licenses-list__button-assigning')
								: t('licenses-list__button-extending')
						}
					/>
				) : (
					<PrimaryButton
						data-testid="assign-license-modal-assign-button"
						disabled={!props.selectedDevice}
						text={
							!props.selectedDevice ||
							!props.hasLicense(props.selectedDevice.deviceId)
								? t('licenses-list__button-assign')
								: t('licenses-list__button-extend')
						}
						onClick={() => {
							props.assignLicense();
						}}
					/>
				)}
			</Stack>
		</>
	);
};

export default LicensesAssignmentSummaryStep;

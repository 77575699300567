import React from 'react';
import { mergeStyleSets, Stack } from '@fluentui/react';
import { IStackItemProps } from '@fluentui/react/lib/components/Stack/StackItem/StackItem.types';

const classes = mergeStyleSets({
	successCongratulationStackItem: {
		fontWeight: 'bold',
		fontSize: '110%',
	},
});

export const SuccessCongratulationStackItem: React.FC<IStackItemProps> = ({
	children,
	...props
}) => {
	return (
		<Stack.Item className={classes.successCongratulationStackItem} {...props}>
			{children}
		</Stack.Item>
	);
};

import * as React from 'react';
import { mergeStyleSets, Stack, StackItem } from '@fluentui/react';
import { useIntl } from '@ysoft/react-intl';

const classes = mergeStyleSets({
	selectCustomerPrompt: {
		fontSize: '1.2rem',
	},
});

const SelectTenantPrompt: React.FC = () => {
	const { t } = useIntl();

	return (
		<Stack>
			<StackItem>
				<h2 data-testid="devices-page__no-customer-selected">
					{t('omni-bridge-devices-list__no-customer-selected__title')}
				</h2>
			</StackItem>
			<StackItem>
				<p
					data-testid="devices-page__select-customer"
					className={classes.selectCustomerPrompt}
				>
					{t('omni-bridge-devices-list__select-customer__prompt')}
				</p>
			</StackItem>
		</Stack>
	);
};

export default SelectTenantPrompt;

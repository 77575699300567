import { IColumn, mergeStyleSets } from '@fluentui/react';
import { useIntl } from '@ysoft/react-intl';
import { useApiContext } from '../../libs/apiContext';
import useSWR from 'swr';
import { useCallback, useEffect, useState } from 'react';
import { filterEntities } from '../../libs/filterEntities';
import { useInView } from 'react-intersection-observer';
import { LicensesAssignmentProps } from './license-assignment/useLicensesAssignment';
import useSearchedTerm from '../../hooks/useSearchedTerm';
import { useConfigContext } from '../../config/configContext';

export interface DisplayedLicense extends Api.License {
	assignedToColumn: string;
}

const licensesPerScroll = 50;

const classNames = mergeStyleSets({
	contentClass: {
		padding: '0rem 1rem 0 1rem',
		margin: '0 auto',
		maxWidth: '75rem',
	},
	loaderTriggerClass: {
		minHeight: '10px',
	},
	visible: {
		visibility: 'visible',
	},
	search: {
		width: '100%',
		maxWidth: '20rem',
	},
});

const useLicenseList = (
	onRenderId: (license: DisplayedLicense) => JSX.Element,
	onRenderAssignButtons: (license: DisplayedLicense) => JSX.Element,
	onRenderLicenseId: (license: DisplayedLicense) => JSX.Element,
	onRenderActivationDate: (license: DisplayedLicense) => JSX.Element,
	onRenderExpirationDate: (license: DisplayedLicense) => JSX.Element
) => {
	const { t } = useIntl();
	const api = useApiContext();
	const configContext = useConfigContext();
	const licensesListHeaderText = t('licenses-list__title');
	const licensesFetcher = (url: string) => api.get(url).then((res) => res.data);
	const { ref: loaderTriggerRef, inView: isLoaderTriggerInView } = useInView();
	const [shownLicenses, setShownLicenses] = useState<DisplayedLicense[]>([]);
	const [filteredLicenses, setFilteredLicenses] = useState<DisplayedLicense[]>(
		[]
	);
	const [isLicenseListCompletelyShown, setIsLicenseListCompletelyShown] =
		useState(false);
	const {
		data: licensesList,
		error: licensesFetcherError,
		mutate: mutateLicenses,
	} = useSWR<Api.License[]>('/licenses', licensesFetcher);

	function assignLicenseHandler(license: Api.License, licenses: Api.License[]) {
		setAssignLicenseModalInfo((prev) => ({
			...prev,
			isOpen: true,
			license: license,
			allLicenses: licenses,
		}));
	}

	const locale = configContext.config.locale;
	const dateFormatOptions = configContext.config.dateFormatOptions;

	const transformDates = useCallback(
		(originalList: Api.License[]): DisplayedLicense[] => {
			return originalList.map((license) => {
				return {
					...license,
					expirationDate: new Date(license.expirationDate).toLocaleDateString(
						locale,
						dateFormatOptions
					),
					created: new Date(license.created).toLocaleDateString(
						locale,
						dateFormatOptions
					),
					activated: license.activated
						? new Date(license.activated).toLocaleDateString(
								locale,
								dateFormatOptions
						  )
						: undefined,
					assignedToColumn: license.assignedToDeviceId
						? license.assignedToDeviceId
						: t('licenses-list__not-assigned'),
				};
			});
		},
		[dateFormatOptions, locale, t]
	);

	const [assignLicenseModalInfo, setAssignLicenseModalInfo] = useState<
		Omit<LicensesAssignmentProps, 'onDismiss'>
	>({
		isOpen: false,
		allLicenses: [],
		mutateLicenses: mutateLicenses,
	});

	function resetShownLicenses() {
		setShownLicenses([]);
		setIsLicenseListCompletelyShown(false);
	}

	const processLicensesListOnNoSearchTermSet = useCallback(() => {
		if (licensesList) {
			setFilteredLicenses(transformDates(licensesList));
			resetShownLicenses();
		}
	}, [licensesList, transformDates]);

	const processLicensesListOnSearchTermSet = useCallback(
		(searchTerm: string) => {
			if (licensesList) {
				setFilteredLicenses(
					filterEntities<DisplayedLicense>(
						searchTerm,
						['id', 'assignedToColumn', 'expirationDate'],
						transformDates(licensesList)
					)
				);
				resetShownLicenses();
			}
		},
		[licensesList, transformDates]
	);

	const { searchedTerm, setSearchedTerm } = useSearchedTerm(
		undefined,
		processLicensesListOnNoSearchTermSet,
		processLicensesListOnSearchTermSet
	);

	useEffect(() => {
		if (
			licensesList &&
			isLoaderTriggerInView &&
			!isLicenseListCompletelyShown
		) {
			if (shownLicenses.length < filteredLicenses.length) {
				if (
					shownLicenses.length + licensesPerScroll >=
					filteredLicenses.length
				) {
					setShownLicenses([...filteredLicenses]);
					setIsLicenseListCompletelyShown(true);
				} else {
					setShownLicenses(
						filteredLicenses.slice(0, shownLicenses.length + licensesPerScroll)
					);
				}
			}
		}
		// we dont want to run update again if shownList.length change, it would fill all items at once
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		licensesList,
		isLoaderTriggerInView,
		isLicenseListCompletelyShown,
		filteredLicenses,
	]);

	const columns: IColumn[] = [
		{
			key: 'licenseCode',
			name: t('licenses-list__license-code'),
			fieldName: 'id',
			minWidth: 100,
			maxWidth: 500,
			isMultiline: true,
			onRender: onRenderLicenseId,
		},
		{
			key: 'assignedTo',
			name: t('licenses-list__assigned-to'),
			fieldName: 'assignedTo',
			minWidth: 200,
			maxWidth: 250,
			isMultiline: true,
			isCollapsible: true,
			onRender: onRenderId,
		},
		{
			key: 'activationDate',
			name: t('licenses-list__activation-date'),
			fieldName: 'activated',
			minWidth: 200,
			maxWidth: 250,
			isMultiline: true,
			isCollapsible: true,
			onRender: onRenderActivationDate,
		},
		{
			key: 'expirationDate',
			name: t('licenses-list__expiration-date'),
			fieldName: 'expirationDate',
			minWidth: 150,
			maxWidth: 200,
			isMultiline: true,
			isCollapsible: true,
			onRender: onRenderExpirationDate,
		},
		{
			key: 'assignButtons',
			name: '',
			fieldName: '',
			minWidth: 120,
			maxWidth: 150,
			onRender: onRenderAssignButtons,
		},
	];

	return {
		data: {
			listProps: {
				columns,
				loaderTriggerRef,
				licensesListHeaderText,
			},
			list: {
				filteredLicenses,
				isLicenseListCompletelyShown,
				licensesList,
				shownLicenses,
			},
			searchedTerm,
			assignLicenseModalInfo,
			isLoaderTriggerInView,
			licensesFetcherError,
		},
		operations: {
			assignLicenseHandler,
			setAssignLicenseModalInfo,
			setSearchedTerm,
		},
		classes: classNames,
	};
};

const ExportFunction = (
	...params: Parameters<typeof useLicenseList>
): ReturnType<typeof useLicenseList> => useLicenseList(...params);
export default ExportFunction;

import { Selection } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { useApiContext } from '../../../libs/apiContext';
import { useIntl } from '@ysoft/react-intl';
import { useConfigContext } from '../../../config/configContext';
import useSearchedTerm from '../../../hooks/useSearchedTerm';
import useInfiniteScrolling from '../../../hooks/useInfiniteScrolling';
import { SwrMutate } from '../../../../types/swrMutate';

export type LicensesAssignmentProps = {
	isOpen: boolean;
	allLicenses?: Api.License[];
	license?: Api.License;
	mutateLicenses: SwrMutate<Api.License[]>;
	onDismiss: () => void;
};

export interface ColumnModel {
	deviceId: string;
	expirationDate: string | undefined;
}

const useLicensesAssignment = (props: LicensesAssignmentProps) => {
	const api = useApiContext();
	const { t } = useIntl();
	const configContext = useConfigContext();

	const detailsListColumns = [
		{
			key: 'deviceId',
			name: t('assign-license-modal__devices-list-device-id'),
			fieldName: 'deviceId',
			minWidth: 100,
			isResizable: false,
		},
		{
			key: 'expirationDate',
			name: t('assign-license-modal__devices-list-expiration'),
			fieldName: 'expirationDate',
			minWidth: 150,
			isResizable: false,
		},
	];

	const { searchedTerm, setSearchedTerm } = useSearchedTerm('modal-q');

	const { swrData, loadMoreTriggerRef, hasReachedEnd } =
		useInfiniteScrolling<Api.DevicesList>(searchedTerm, '/devices');

	const devices = swrData.data
		? ([] as Api.Device[]).concat(
				...swrData.data.map(
					// can be rewritten to .flat() as the flat function gains more support form browsers
					(devicesResponse) => devicesResponse.items
				)
		  )
		: [];

	const [assigningLicenseState, setAssigningLicenseState] = useState<{
		value: 'idle' | 'inProgress' | 'success';
		error?: Api.ErrorResponse;
	}>({ value: 'idle' });
	useEffect(() => {
		if (props.isOpen) {
			setAssigningLicenseState({ value: 'idle', error: undefined });
		}
	}, [props.isOpen]);

	const hasLicense = (deviceId: string): boolean => {
		if (!deviceId || !props.allLicenses) {
			return false;
		}
		return props.allLicenses.some(
			(license) => license.assignedToDeviceId === deviceId
		);
	};

	const getLicenseOfDevice = (deviceId: string): Api.License | undefined => {
		if (hasLicense(deviceId)) {
			return props.allLicenses?.find(
				(license) => license.assignedToDeviceId === deviceId
			);
		}
	};

	const getExpirationForDevice = (deviceId: string): string => {
		if (!props.allLicenses) {
			return '';
		}

		const deviceLicenses = props.allLicenses.filter(
			(license) => license.assignedToDeviceId === deviceId
		);

		const latestExpiration = deviceLicenses.reduce(
			(currentMaximum, currentDate) => {
				return Math.max(
					currentMaximum,
					convertDateStringToNumber(currentDate.expirationDate)
				);
			},
			0
		);

		return new Date(latestExpiration).toLocaleDateString(
			configContext.config.locale,
			configContext.config.dateFormatOptions
		);
	};

	const convertDateStringToNumber = (dateString: string): number => {
		return new Date(dateString).getTime();
	};

	const columnItems: ColumnModel[] = devices.map((device) => {
		return {
			deviceId: device.deviceId,
			expirationDate: hasLicense(device.deviceId)
				? getExpirationForDevice(device.deviceId)
				: undefined,
		} as ColumnModel;
	});

	const [selectedDevice, setSelectedDevice] = useState<Api.Device>();
	const selection = new Selection({
		onSelectionChanged: () => {
			setSelectedDevice(selection.getSelection()[0] as unknown as Api.Device);
		},
		getKey: (columnModel: ColumnModel) => {
			return columnModel.deviceId;
		},
		items: columnItems,
	});

	const [assignLicenseModalStep, setAssignLicenseModalStep] = useState<
		'select' | 'summary'
	>('select');

	const assignLicense = () => {
		if (!props.license || !selectedDevice) {
			console.error(
				'The user did not select license or device. Can not assign license.'
			);
			return;
		}
		const payload = {
			deviceId: selectedDevice.deviceId,
			referenceLicenseId: getLicenseOfDevice(selectedDevice.id)?.id,
		};
		setAssigningLicenseState({ value: 'inProgress' });
		api
			.post(`/licenses/${props.license.id}/assign`, payload)
			.then(() => {
				setAssigningLicenseState({ value: 'success', error: undefined });
				setAssignLicenseModalStep('select');
				swrData.setSize(1);
				swrData.mutate();
				props
					.mutateLicenses(
						(prevLicenses) =>
							prevLicenses?.map((prevLicense) =>
								prevLicense.id === props.license?.id
									? {
											...prevLicense,
											assignedToDeviceId: selectedDevice.deviceId,
									  }
									: prevLicense
							),
						false
					)
					.then(() => {
						if (props.license) {
							api
								.get<Api.License>(`/licenses/${props.license.id}`)
								.then((response) => {
									props.mutateLicenses(
										(prevLicenses) =>
											prevLicenses?.map((prevLicense) =>
												prevLicense.id === props.license?.id
													? response.data
													: prevLicense
											),
										false
									);
								})
								.catch(() => {
									console.warn(
										'The new license data fetch failed. The UI state is rerendered, but not validated with the API.'
									);
								});
						}
					});
			})
			.catch((err) => {
				setAssigningLicenseState({
					value: 'idle',
					error: err.response ? err.response : err,
				});
			});
	};

	return {
		data: {
			devices: {
				values: devices,
				error: swrData.error,
				isValidating: swrData.isValidating,
				selection: {
					value: selection,
					selectedDevice,
				},
			},
			detailsListColumns,
			assignLicense: {
				state: assigningLicenseState,
			},
			searchedTerm,
			columnItems,
			assignLicenseModalStep,
			hasReachedEnd,
		},
		operations: {
			loadMoreTriggerRef,
			assignLicense,
			hasLicense,
			getLicenseOfDevice,
			setSearchedTerm,
			setAssignLicenseModalStep,
			getExpirationForDevice,
		},
	};
};

const ExportFunction = (
	props: LicensesAssignmentProps
): ReturnType<typeof useLicensesAssignment> => useLicensesAssignment(props);
export default ExportFunction;

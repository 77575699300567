import { useInView } from 'react-intersection-observer';
import {
	useSWRInfinite,
	SWRInfiniteResponse,
	SWRInfiniteConfiguration,
} from 'swr';
import { useEffect } from 'react';
import { useApiContext } from '../libs/apiContext';

const defaultSwrOptions: SWRInfiniteConfiguration = {
	revalidateOnFocus: false,
	revalidateOnMount: false,
};

const useInfiniteScrolling = <
	TFetchedType extends Api.InfiniteLoadingProps | undefined
>(
	searchedTerm: string,
	fetchUrl: string,
	swrOptions?: SWRInfiniteConfiguration,
	additionalQueryParams?: string[]
): {
	swrData: SWRInfiniteResponse<TFetchedType>;
	loadMoreTriggerRef: (node?: Element | null | undefined) => void;
	hasReachedEnd: boolean;
} => {
	const api = useApiContext();
	const { ref: loadMoreTriggerRef, inView: isLoaderTriggerVisible } =
		useInView();

	const getKey = (pageIndex: number, previousPageData: TFetchedType | null) => {
		if (
			previousPageData &&
			(!previousPageData.nextPageInfo ||
				!previousPageData.nextPageInfo.partitionKey ||
				!previousPageData.nextPageInfo.rowKey)
		) {
			return null; // reached the end
		}

		const usedAdditionalQueryParams =
			additionalQueryParams && additionalQueryParams.length > 0
				? `&${additionalQueryParams?.join('&')}`
				: '';
		if (pageIndex === 0) {
			return `${fetchUrl}?q=${searchedTerm}${usedAdditionalQueryParams}`;
		}
		return `${fetchUrl}?q=${searchedTerm}${usedAdditionalQueryParams}&nextPartitionKey=${previousPageData?.nextPageInfo?.partitionKey}&nextRowKey=${previousPageData?.nextPageInfo?.rowKey}`;
	};
	const swrData = useSWRInfinite<TFetchedType>(
		getKey,
		(url: string) => api.get(url).then((res) => res.data),
		{ ...defaultSwrOptions, ...swrOptions }
	);

	const lastLoadedPageInfo =
		swrData.data && swrData.data[swrData.data.length - 1]?.nextPageInfo;
	const hasReachedEnd =
		swrData.error ||
		(swrData.data &&
			(!lastLoadedPageInfo ||
				!lastLoadedPageInfo.partitionKey ||
				!lastLoadedPageInfo.rowKey));

	const setSize = swrData.setSize;
	const isValidating = swrData.isValidating;
	useEffect(() => {
		if (isLoaderTriggerVisible && !isValidating && !hasReachedEnd) {
			setSize((prevSize) => prevSize + 1);
		}
	}, [hasReachedEnd, isLoaderTriggerVisible, isValidating, setSize]);

	return {
		swrData,
		loadMoreTriggerRef,
		hasReachedEnd,
	};
};

export default useInfiniteScrolling;

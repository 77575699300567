import {
	IStackTokens,
	Persona,
	Stack,
	IContextualMenuProps,
	Modal,
	mergeStyleSets,
} from '@fluentui/react';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { useIntl } from '@ysoft/react-intl';
import React, { useMemo, useState } from 'react';
import { useUserContext } from '../../libs/userContext';
import TenantsHeaderList from './TenantsHeaderList';
import useAuth from '../../libs/auth';
import RequestHelpForm from './request-help/RequestHelpForm';
import { useHistory } from 'react-router-dom';

const spacing: IStackTokens = {
	childrenGap: '1rem',
};

const classes = mergeStyleSets({
	buttonStyles: {
		padding: 0,
		fontWeight: 'bold',
	},
	stackItems: {
		width: 'auto',
	},
	stackItemsPanel: { width: '100%' },
});

export const ActionsPanelElements: React.FC<{ isPanel?: boolean }> = (
	props
) => {
	const { keycloak } = useAuth();
	const { t } = useIntl();
	const { userInfo } = useUserContext();
	const [isRequestHelpModalOpen, setIsRequestHelpModalOpen] = useState(false);
	const history = useHistory();

	const displayName =
		userInfo.given_name && userInfo.family_name
			? `${userInfo.given_name} ${userInfo.family_name}`
			: userInfo.preferred_username;

	const initials =
		userInfo.given_name && userInfo.family_name
			? `${userInfo.given_name.charAt(0)}${userInfo.family_name.charAt(0)}`
			: '??';

	const keycloakAuthenticated = keycloak?.authenticated;
	const items = useMemo<IContextualMenuProps>(() => {
		return {
			shouldFocusOnMount: true,
			items: keycloakAuthenticated
				? [
						{
							'data-testid': 'header_logout-button',
							key: 'logoutItem',
							text: t('app__logout'),
							onClick: () => {
								keycloak?.logout();
							},
						},
				  ]
				: [],
		};
	}, [keycloak, keycloakAuthenticated, t]);

	const closeRequestHelpHandler = () => setIsRequestHelpModalOpen(false);

	const navigateToDocumentation = () => {
		history.push('/documentation');
	};

	const stackItemClass = props.isPanel
		? classes.stackItemsPanel
		: classes.stackItems;
	return keycloakAuthenticated ? (
		<Stack horizontal={!props.isPanel} tokens={spacing} verticalAlign="center">
			<Stack.Item className={stackItemClass}>
				<PrimaryButton
					data-testid="documentation-button"
					iconProps={{ iconName: 'BookAnswers' }}
					onClick={() => navigateToDocumentation()}
				>
					{t('documentation__header-button')}
				</PrimaryButton>
			</Stack.Item>
			<Stack.Item className={stackItemClass}>
				<PrimaryButton
					onClick={() => setIsRequestHelpModalOpen(true)}
					data-testid="request-help-button"
					iconProps={{ iconName: 'Telemarketer' }}
				>
					{t('request-help__header-button')}
				</PrimaryButton>
				<Modal
					isOpen={isRequestHelpModalOpen}
					onDismiss={closeRequestHelpHandler}
				>
					<RequestHelpForm onDismiss={closeRequestHelpHandler} />
				</Modal>
			</Stack.Item>
			<Stack.Item className={stackItemClass}>
				<TenantsHeaderList />
			</Stack.Item>
			<Stack horizontal tokens={spacing} verticalAlign="center">
				<Stack.Item>
					<Persona
						data-testid="header_user-avatar"
						text={displayName}
						size={24}
						imageUrl={userInfo.avatar_url}
						imageInitials={initials}
						hidePersonaDetails={true}
					/>
				</Stack.Item>
				<Stack.Item>
					<PrimaryButton
						data-testid="header_user-menu"
						text={displayName}
						menuProps={items}
						className={classes.buttonStyles}
						onRenderMenuIcon={() => null}
					/>
				</Stack.Item>
			</Stack>
		</Stack>
	) : (
		<div />
	);
};

import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

const useSearchedTerm = (
	queryParameter = 'q',
	onSearchedTermNotSet?: () => void,
	onSearchedTermSet?: (searchTerm: string) => void,
	additionalQueryParameter?: string
) => {
	const history = useHistory();
	const location = useLocation();

	const [searchedTerm, setSearchedTerm] = useState(
		new URLSearchParams(location.search).get(queryParameter) ?? ''
	);

	const [additionalSearchedTerm, setAdditionalSearchedTerm] = useState<string>(
		additionalQueryParameter
			? new URLSearchParams(location.search).get(additionalQueryParameter) ?? ''
			: ''
	);

	useEffect(() => {
		if (searchedTerm === '' && additionalSearchedTerm === '') {
			history.push(location.pathname);
		} else {
			let searchQuery = '?';
			const params: string[] = [];
			if (searchedTerm) {
				params.push(`${queryParameter}=${searchedTerm}`);
			}
			if (additionalSearchedTerm) {
				params.push(`${additionalQueryParameter}=${additionalSearchedTerm}`);
			}
			searchQuery += params.join('&');
			history.push({
				pathname: location.pathname,
				search: searchQuery,
			});
		}

		if (searchedTerm === '') {
			if (onSearchedTermNotSet) {
				onSearchedTermNotSet();
			}
		} else {
			if (onSearchedTermSet) {
				onSearchedTermSet(searchedTerm);
			}
		}
	}, [
		history,
		searchedTerm,
		queryParameter,
		onSearchedTermNotSet,
		onSearchedTermSet,
		additionalSearchedTerm,
		additionalQueryParameter,
		location.pathname,
	]);

	return {
		searchedTerm,
		setSearchedTerm,
		additionalSearchedTerm,
		setAdditionalSearchedTerm,
	};
};

const ExportFunction = (
	...params: Parameters<typeof useSearchedTerm>
): ReturnType<typeof useSearchedTerm> => useSearchedTerm(...params);
export default ExportFunction;

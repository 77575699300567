import React from 'react';
import { useLocation } from 'react-router-dom';
import useSWR from 'swr';
import {
	Nav,
	INavLink,
	INavLinkGroup,
	IRenderFunction,
} from '@fluentui/react';
import { useIntl } from '@ysoft/react-intl';
import { useApiContext } from '../../libs/apiContext';
import useApp from '../../useApp';
import { useConfigContext } from '../../config/configContext';
export interface IMenuProps {
	isMobile: boolean;
}

const Menu: React.FC<IMenuProps> = (props) => {
	const { operations, classes } = useApp();
	const { t } = useIntl();
	const location = useLocation();
	const api = useApiContext();
	const menuFetcher = (url: string) => api.get(url).then((res) => res.data);
	const { data: menu } = useSWR<Api.DocumentationItem>(
		'/documentation/menu.json',
		menuFetcher
	);
	const { config } = useConfigContext();

	const urls = {
		devices: '/devices',
		licenses: '/licenses',
		documentation: '/documentation',
	};

	const createDocumenationNavLink = (
		value: Api.DocumentationItem,
		index: number,
		tree: Api.DocumentationItem[]
	): INavLink => {
		const subNavLinks =
			value?.subTree?.map(createDocumenationNavLink) ?? undefined;
		const url = `${urls.documentation}/${value.path}`;

		return {
			name: value.title,
			url: url,
			key: url,
			isExpanded:
				subNavLinks &&
				subNavLinks.length !== 0 &&
				location.pathname.startsWith(url),
			links: subNavLinks,
			automationId: url,
		};
	};

	const documentationLinks = menu?.subTree?.map(createDocumenationNavLink);
	const baseNavLinksDevice = [
		{
			name: t('omni-bridges'),
			url: urls.devices,
			key: urls.devices,
			icon: 'Devices2',
			automationId: 'omni-bridges',
		},
	];
	const baseNavLinksLicense = [
		{
			name: t('licenses'),
			url: urls.licenses,
			key: urls.licenses,
			icon: 'Certificate',
			automationId: 'licenses',
		},
	];
	const baseNavLinksDocumentation = [
		{
			name: t('documentation'),
			url: urls.documentation,
			key: urls.documentation,
			icon:
				!documentationLinks || documentationLinks.length === 0
					? 'BookAnswers'
					: undefined,
			isExpanded: location.pathname.startsWith(urls.documentation),
			links: documentationLinks,
			automationId: 'documentation',
		},
	];
	const baseNavLinks: INavLink[] = config.licenseToggle ?
		[
			...baseNavLinksDevice,
			...baseNavLinksLicense,
			...baseNavLinksDocumentation,
		] : [
			...baseNavLinksDevice, 
			...baseNavLinksDocumentation
		];

	const navLinks: INavLinkGroup[] = [
		{
			links: baseNavLinks,
		},
	];

	const mobileNavLinks: INavLinkGroup[] = [
		{
			name: t('menu__mobile-heading'),
			collapseByDefault: true,
			links: baseNavLinks,
		},
	];

	const onRenderLink: IRenderFunction<INavLink> = (
		props?: INavLink,
		defaultRender?: (props?: INavLink) => JSX.Element | null
	) => {
		return (
			<div data-testid={props && props.automationId}>
				{defaultRender && defaultRender(props)}
			</div>
		);
	};

	const menuRenderer = (
		<div data-testid="menu">
			<Nav
				groups={navLinks}
				selectedKey={location.pathname}
				className={classes.navClass}
				styles={classes.commonNavStylesStyle}
				onLinkClick={operations.navigationLinkClick}
				onRenderLink={onRenderLink}
			/>
		</div>
	);

	const mobileMenuRenderer = (
		<div data-testid="menu-mobile">
			<Nav
				groups={mobileNavLinks}
				selectedKey={location.pathname}
				className={classes.mobileNavClass}
				styles={classes.commonNavStylesStyle}
				onLinkClick={operations.navigationLinkClick}
			/>
		</div>
	);

	return props.isMobile ? mobileMenuRenderer : menuRenderer;
};

export default Menu;

import { mergeStyles, Stack } from '@fluentui/react';
import React from 'react';
import { CertificateIcon } from '@fluentui/react-icons-mdl2';

const iconClass = mergeStyles({
	fontSize: '150%',
});

const LicenseDetail: React.FC<{ license?: Api.License }> = (props) => {
	return (
		<Stack
			data-testid="assign-license-modal-license-info"
			horizontal
			verticalAlign="center"
			tokens={{ childrenGap: 10 }}
		>
			<CertificateIcon className={iconClass} />
			<span>{props.license?.id}</span>
		</Stack>
	);
};

export default LicenseDetail;

export const getSpecificErrorMessageTranslationStringKey = (
	errorData: Api.ErrorResponseData
): string => {
	if (errorData?.specificStatusCode) {
		switch (errorData?.specificStatusCode) {
			case '400.02':
				return 'specific_api_error__validation-error';
			case '400.04':
				return 'specific_api_error__invalid-gateway-mode';
			case '400.05':
				return 'specific_api_error__license-has-expired';
			case '409.01':
				return 'specific_api_error__license-already-assigned';
			case '404.02':
				return 'specific_api_error__device-not-found';
			case '404.05':
				return 'specific_api_error__license-not-found';
			case '404.06':
				return 'specific_api_error__valid-license-not-found';
			case '404.08':
				return 'specific_api_error__invalid-license-type';
			case '404.09':
				return 'specific_api_error__missing-twin';
			default:
				return 'error__general';
		}
	}
	switch (errorData?.status) {
		case 403:
			return 'error__no-access';
		default:
			return 'error__general';
	}
};

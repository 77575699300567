import React, { useCallback, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { MessageBar, MessageBarType, Stack } from '@fluentui/react';
import ConfigurationTextField from './ConfigurationTextField';
import ConfigurationButtonBar from './ConfigurationButtonBar';
import { useIntl } from '@ysoft/react-intl';
import { useApiContext } from '../../libs/apiContext';
import { NotificationType } from '../../general-components/NotificationBar';
import { getSpecificErrorMessageTranslationStringKey } from '../../libs/getSpecificErrorMessageTranslationStringKey';
import { SwrMutate } from '../../../types/swrMutate';
import { useConfigContext } from '../../config/configContext';

export interface NotificationData {
	content: string;
	notificationType: NotificationType;
}

export type ConfigurationProps = {
	deviceId: string;
	back: () => void;
	license: Api.License | undefined | Record<string, never>;
	licenseError: { response: Api.ErrorResponse };
	mutateValidLicense: SwrMutate<Api.License | Record<string, never>>;
	moduleTwin: Api.ModuleTwin;
	mutateModuleTwin: SwrMutate<Api.ModuleTwin>;
};

const Configuration: React.FC<ConfigurationProps> = (props) => {
	const { t } = useIntl();
	const api = useApiContext();
	const { config } = useConfigContext();
	const [sendingData, setSendingData] = useState(false);
	const [notificationVisible, setNotificationVisible] = useState(false);
	const [isLoadingConfiguration, setLoadingConfiguration] = useState(false);
	const [dataChanged, setDataChanged] = useState(false);
	const [shownData, setShownData] = useState<string>('');
	const [notificationData, setNotificationData] = useState<NotificationData>({
		content: '',
		notificationType: NotificationType.Info,
	});

	const canEditConfiguration = (): boolean => {
		const hasExpiredValidLicense =
			props.license && new Date(props.license.expirationDate) < new Date();

		return (
			!config.licenseToggle ||
			!(hasExpiredValidLicense || hasNoAssignedLicense())
		);
	};

	const hasNoAssignedLicense = (): boolean => {
		return (
			props.licenseError &&
			props.licenseError.response.data?.specificStatusCode === '404.06'
		);
	};

	const mutateModuleTwin = props.mutateModuleTwin;
	const reloadPortConfigurationForDevice = useCallback(
		(deviceId: string, isMigration: boolean) => {
			const isMigrationFlag = isMigration ? '?isMigration=true' : '';
			setLoadingConfiguration(true);
			api
				.get(`/configuration/${deviceId}${isMigrationFlag}`)
				.then((res) => {
					mutateModuleTwin(res.data, !isMigration);
					setNotificationData({
						content: t('port-migration__load-data-from-success', {
							deviceId,
						}),
						notificationType: NotificationType.Info,
					});
					setNotificationVisible(true);
				})
				.catch((err) => {
					setNotificationData({
						content: t('port-migration__load-data-from-failure', {
							deviceId: deviceId,
							error: `${err?.response?.data?.title}`,
						}),
						notificationType: NotificationType.Error,
					});
					setNotificationVisible(true);
				})
				.finally(() => {
					setLoadingConfiguration(false);
				});
		},
		[api, mutateModuleTwin, t]
	);

	const apply = () => {
		setSendingData(true);
		api
			.post(`/configuration/${props.deviceId}`, shownData, {
				headers: { 'Content-Type': 'application/json' },
			})
			.then((res) => {
				setNotificationData({
					content: t('port-configuration__apply-button-done'),
					notificationType: NotificationType.Info,
				});

				setNotificationVisible(true);
				mutateModuleTwin(res.data);
			})
			.catch((err) => {
				if (err?.response?.data?.errors) {
					const keys = Object.keys(err?.response?.data?.errors);
					setNotificationData({
						content: `${t('error__word')}: ${
							err?.response?.data?.errors
								? err?.response?.data?.errors[keys[0]][0] // showing the first error at the moment
								: err?.response?.statusText
						}`,
						notificationType: NotificationType.Error,
					});
				}
				setNotificationData({
					content: t(
						getSpecificErrorMessageTranslationStringKey(err?.response?.data)
					),
					notificationType: NotificationType.Error,
				});

				setNotificationVisible(true);
			})
			.finally(() => {
				setSendingData(false);
			});

		setDataChanged(false);
	};

	return (
		<>
			<Prompt
				data-testid="port-configuration__prompt"
				when={dataChanged}
				message={t('port-configuration__prompt-text')}
			/>
			{!canEditConfiguration() && (
				<Stack.Item>
					<MessageBar
						messageBarType={MessageBarType.error}
						data-testid="port-configuration__error-message-bar"
					>
						{t('port-configuration__invalid-license')}
					</MessageBar>
				</Stack.Item>
			)}
			<Stack.Item>
				<ConfigurationTextField
					license={props.license}
					moduleTwin={props.moduleTwin}
					shownData={shownData}
					setShownData={setShownData}
					setDataChanged={setDataChanged}
					canEditConfiguration={canEditConfiguration}
					setNotificationVisible={setNotificationVisible}
					notificationVisible={notificationVisible}
				/>
			</Stack.Item>
			<Stack.Item>
				<ConfigurationButtonBar
					deviceId={props.deviceId}
					apply={apply}
					back={props.back}
					license={props.license}
					mutateValidLicense={props.mutateValidLicense}
					reloadPortConfigurationForDevice={reloadPortConfigurationForDevice}
					canEditConfiguration={canEditConfiguration}
					isLoadingConfiguration={isLoadingConfiguration}
					shownData={shownData}
					sendingData={sendingData}
					dataChanged={dataChanged}
					notificationVisible={notificationVisible}
					setNotificationVisible={setNotificationVisible}
					notificationData={notificationData}
				/>
			</Stack.Item>
		</>
	);
};

export default Configuration;

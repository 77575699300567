import React from 'react';
import { useIntl } from '@ysoft/react-intl';
import { DefaultButton, Stack } from '@fluentui/react';
import ReactMarkdown from 'react-markdown';
import { SuccessImageStackItem } from '../../../general-components/SuccessImageStackItem';
import { SuccessCongratulationStackItem } from '../../../general-components/SuccessCongratulationStackItem';

const LicensesAssignmentSuccessStep: React.FC<{
	onDismiss: () => void;
	device?: Api.Device;
	license?: Api.License;
}> = (props) => {
	const { t } = useIntl();
	const license = props.license?.id;
	const deviceId = props.device?.deviceId;
	return (
		<>
			<Stack tokens={{ childrenGap: 20 }} horizontalAlign="center">
				<SuccessImageStackItem />
				<SuccessCongratulationStackItem>
					{t('assign-license-modal__success-congratulations')}
				</SuccessCongratulationStackItem>
				{license && deviceId && (
					<Stack.Item>
						<ReactMarkdown>
							{t('assign-license-modal__success-license-device-info', {
								license,
								deviceId,
							})}
						</ReactMarkdown>
					</Stack.Item>
				)}
				<Stack.Item>
					{t('assign-license-modal__success-information')}
				</Stack.Item>
			</Stack>
			<Stack horizontalAlign="end">
				<DefaultButton
					data-testid="omni-close"
					text={t('general__button-close')}
					onClick={props.onDismiss}
				/>
			</Stack>
		</>
	);
};

export default LicensesAssignmentSuccessStep;

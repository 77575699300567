import { ReactKeycloakProvider } from '@react-keycloak/web';
import { AuthClientEvent, AuthClientError } from '@react-keycloak/core';
import Keycloak from 'keycloak-js';
import { Redirect, useLocation } from 'react-router-dom';
import { isAuthMocked } from '../config/config';
import { useConfigContext } from '../config/configContext';
import useAuth from './auth';
import { useState } from 'react';
import KeycloakNotWorkingPage from '../components/KeycloakNotWorkingPage';

const tokenLogger = (tokens: unknown) => {
	//Use only for debug purposes - this should be disabled in production
	//console.log('onKeycloakTokens', tokens);
};

const AuthProvider: React.FC = (props) => {
	const { config } = useConfigContext();
	const keycloak = Keycloak(config.keycloak);

	const [failedKeycloak, setFailedKeycloack] = useState(false);

	const eventLogger = (event: AuthClientEvent, error?: AuthClientError) => {
		if (event === 'onInitError') {
			setFailedKeycloack(true);
		}
	};

	return isAuthMocked ? (
		<>{props.children}</>
	) : failedKeycloak ? (
		<KeycloakNotWorkingPage />
	) : (
		<ReactKeycloakProvider
			authClient={keycloak}
			onEvent={eventLogger}
			onTokens={tokenLogger}
		>
			<KeycloakRedirect />
			{props.children}
		</ReactKeycloakProvider>
	);
};

const KeycloakRedirect: React.FC = () => {
	const { keycloak, initialized } = useAuth();

	const location = useLocation<{ [key: string]: unknown }>();
	const currentLocationState = location.state || {
		from: { pathname: '/' },
	};

	if (initialized && !keycloak.authenticated) {
		keycloak.login();
		if (keycloak.authenticated) {
			return <Redirect to={currentLocationState?.from as string} />;
		}
	}

	return <div />;
};

export default AuthProvider;

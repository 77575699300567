export const isLicenseExpired = (license: Api.License | undefined): boolean => {
	return !!license && new Date(license?.expirationDate) < new Date();
};

export const licenseActivationIsExpired = (
	license: Api.License | undefined
): boolean => {
	const licenseActivated = !!license?.activated;
	const licenseCreatedInPast =
		!!license && new Date(license.created) < new Date();
	const activationExpired = !licenseActivated && licenseCreatedInPast;
	return activationExpired;
};

export const licenseIsNotActivated = (
	license: Api.License | undefined
): boolean => {
	return !!license && !license.activated;
};

export const licenseHasActivationScheduledInFuture = (
	license: Api.License | undefined
): boolean => {
	return (
		!!license && !!license.activated && new Date(license.activated) > new Date()
	);
};

export const licenseNotAssignable = (
	license: Api.License | undefined
): boolean => {
	return isLicenseExpired(license) || licenseActivationIsExpired(license);
};

export const canSeeAssignLicenseButton = (
	license: Api.License | undefined
): boolean => {
	const assignedToDeviceId = !!license && license.assignedToDeviceId;
	return (
		!isLicenseExpired(license) &&
		!assignedToDeviceId &&
		!licenseActivationIsExpired(license)
	);
};

export const canSeeUnAssignLicenseButton = (
	license: Api.License | undefined
): boolean => {
	if (!license) {
		return false;
	}
	return !!license.assignedToDeviceId && !isLicenseExpired(license);
};

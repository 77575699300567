import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createIntl, IntlProvider } from '@ysoft/react-intl';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import ConfigContextProvider from './config/configContext';
import ApiProvider from './libs/apiContext';
import AuthProvider from './libs/authProvider';
import UserContextProvider from './libs/userContext';

const intl = createIntl({
	locale: 'en-US',
	localePath: '/locales',
	fallbackLocale: 'en-US',
	version: new Date().getTime().toString(),
});

initializeIcons();

ReactDOM.render(
	<React.StrictMode>
		<ConfigContextProvider>
			<Router>
				<IntlProvider intl={intl}>
					<AuthProvider>
						<UserContextProvider>
							<ApiProvider>
								<App />
							</ApiProvider>
						</UserContextProvider>
					</AuthProvider>
				</IntlProvider>
			</Router>
		</ConfigContextProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useCallback, useEffect } from 'react';
import { TextField } from '@fluentui/react';
import { useIntl } from '@ysoft/react-intl';
import useConfigurationValidation from './useConfigurationValidation';
import { useConfigContext } from '../../config/configContext';

export type ConfigurationTextFieldProps = {
	license: Api.License | undefined | Record<string, never>;
	moduleTwin: Api.ModuleTwin;
	shownData: string;
	setShownData: React.Dispatch<React.SetStateAction<string>>;
	setDataChanged: React.Dispatch<React.SetStateAction<boolean>>;
	canEditConfiguration: () => boolean;
	setNotificationVisible: (value: React.SetStateAction<boolean>) => void;
	notificationVisible: boolean;
};

const ConfigurationTextField: React.FC<ConfigurationTextFieldProps> = (
	props
) => {
	const { t } = useIntl();
	const { validations } = useConfigurationValidation();
	const { config } = useConfigContext();

	const getErrorMessage = (
		value: string,
		license: Api.License | undefined | Record<string, never>
	): string => {
		const jsonValidation = validations.validateJSON(value);
		if (jsonValidation !== '') {
			return jsonValidation;
		}

		const licenseTypeValidation = validations.validateGatewayConfigurationMode(
			value,
			license
		);

		if (licenseTypeValidation !== '') {
			return licenseTypeValidation;
		}

		const openVpnConfigurationValidation =
			validations.validateOpenVpnConfiguration(value);
		if (openVpnConfigurationValidation !== '') {
			return openVpnConfigurationValidation;
		}
		const gatewayModeValidation = validations.validateGatewayConfiguration(
			license,
			value
		);
		if (gatewayModeValidation !== '') {
			return gatewayModeValidation;
		}
		const dnsServerConfigurationValidation =
			validations.validateDnsServerConfiguration(value);
		if (dnsServerConfigurationValidation !== '') {
			return dnsServerConfigurationValidation;
		}
		const dhcpConfigurationValidation = validations.validateDhcpConfiguration(
			license,
			value
		);
		if (dhcpConfigurationValidation !== '') {
			return dhcpConfigurationValidation;
		}
		const portForwardingRulesValidation =
			validations.validatePortForwardingRules(value);
		if (portForwardingRulesValidation !== '') {
			return portForwardingRulesValidation;
		}
		return '';
	};

	const getGatewayMode = useCallback(
		(
			license: Api.License | undefined | Record<string, never>,
			twin: Api.ModuleTwin
		) => {
			if (config.licenseToggle && license?.type) {
				return license.type;
			}
			if (twin.gatewayConfiguration?.mode) {
				switch (twin.gatewayConfiguration.mode) {
					case 's2s':
						return 'SiteToSite';
					case 'p2s':
						return 'PointToSite';
				}
			}
			return undefined;
		},
		[config.licenseToggle]
	);

	const getS2Smodel = (twin: Api.ModuleTwin) => {
		return Object.assign(
			{},
			{
				openVpnConfiguration: twin.openVpnConfiguration,
			},
			{
				gatewayConfiguration: twin.gatewayConfiguration,
			},
			{
				dnsServerConfiguration: twin.dnsServerConfiguration,
			},
			{
				portForwardingRules: twin.portForwardingRules,
			},
			{
				dhcpConfiguration: twin.dhcpConfiguration,
			}
		);
	};

	const getP2Smodel = (twin: Api.ModuleTwin) => {
		return Object.assign(
			{},
			{
				openVpnConfiguration: twin.openVpnConfiguration,
			},
			{
				gatewayConfiguration: twin.gatewayConfiguration,
			},
			{
				dnsServerConfiguration: twin.dnsServerConfiguration,
			},
			{
				portForwardingRules: twin.portForwardingRules,
			}
		);
	};

	const getModelFactory = useCallback(
		(
			license: Api.License | undefined | Record<string, never>,
			twin: Api.ModuleTwin
		) => {
			switch (getGatewayMode(license, twin)) {
				case 'PointToSite':
					return getP2Smodel(twin);
				case 'SiteToSite':
					return getS2Smodel(twin);
				default:
					return undefined;
			}
		},
		[getGatewayMode]
	);

	const getFormattedTwin = useCallback(
		(
			license: Api.License | undefined | Record<string, never>,
			twinData: string
		) => {
			try {
				const twin = JSON.parse(twinData);
				const strippedData = getModelFactory(license, twin);
				return JSON.stringify(strippedData, null, 2);
			} catch {
				return twinData;
			}
		},
		[getModelFactory]
	);

	const setShownData = props.setShownData;
	const license = props.license;
	const moduleTwin = props.moduleTwin;
	useEffect(() => {
		setShownData(getFormattedTwin(license, JSON.stringify(moduleTwin)));
	}, [moduleTwin, license, setShownData, getFormattedTwin]);

	return (
		<TextField
			data-testid="port-configuration__text-field"
			label={t('port-configuration__text-field-label')}
			onChange={(_event, newValue) => {
				setShownData(newValue ?? '');
				props.setDataChanged(true);
			}}
			value={props.shownData}
			multiline
			resizable={false}
			rows={30}
			readOnly={!props.canEditConfiguration()}
			errorMessage={
				props.canEditConfiguration()
					? getErrorMessage(props.shownData, props.license)
					: undefined
			}
			spellCheck={false}
			onClick={() => {
				if (props.notificationVisible) {
					props.setNotificationVisible(false);
				}
			}}
		/>
	);
};

export default ConfigurationTextField;

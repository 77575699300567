import React, { useState } from 'react';
import {
	DefaultButton,
	DetailsListLayoutMode,
	mergeStyles,
	Modal,
	SelectionMode,
	Stack,
} from '@fluentui/react';
import { Text } from '@fluentui/react/lib/Text';
import { useIntl } from '@ysoft/react-intl';
import { DetailsList } from '../../general-components/DetailsList';
import useLicenseList, { DisplayedLicense } from './useLicenseList';
import LicensesAssignmentModal from './license-assignment/LicensesAssignmentModal';
import LicensesUnassignmentModal from './license-unassignment/LicensesUnassignmentModal';
import { generateLoading } from '../../libs/generateLoading';
import { ErrorMessageBar } from '../../general-components/ErrorMessageBar';
import { Badge, Tone } from '../../general-components/Badge';
import HeadingWithSearch from '../HeadingWithSearch';
import {
	canSeeAssignLicenseButton,
	canSeeUnAssignLicenseButton,
	licenseActivationIsExpired,
	licenseHasActivationScheduledInFuture,
	licenseIsNotActivated,
} from '../../libs/licensesHelpers';

const actionButtonClass = mergeStyles({
	width: '85%',
});

const LicensesList: React.FC = () => {
	const { data, operations, classes } = useLicenseList(
		onRenderId,
		onRenderButtons,
		onRenderLicenseCode,
		onRenderActivationDate,
		onRenderExpirationDate
	);

	const [isUnassigningLicenseModalOpen, setIsUnassigningLicenseModalOpen] =
		useState(false);

	const { t } = useIntl();

	function onRenderLicenseCode(license: Api.License): JSX.Element {
		return (
			<div
				data-testid="licenses-list-row__license-code"
				data-licensetype={license.type}
			>
				{license.id}
			</div>
		);
	}

	function onRenderId(license: DisplayedLicense): JSX.Element {
		return (
			<div data-testid="licenses-list-row__assigned-to-device-id">
				{license.assignedToColumn}
			</div>
		);
	}

	const [selectedLicense, setSelectedLicense] = useState<
		Api.License | undefined
	>();

	const unassignLicenseHandler = (license: Api.License) => {
		setIsUnassigningLicenseModalOpen(true);
		setSelectedLicense(license);
	};

	function onRenderButtons(license: DisplayedLicense): JSX.Element {
		return (
			<>
				{canSeeAssignLicenseButton(license) && (
					<DefaultButton
						className={actionButtonClass}
						data-testid="licenses-list-row__assign-button"
						text={t('licenses-list__button-assign')}
						onClick={() =>
							operations.assignLicenseHandler(
								license,
								data.list.licensesList ?? []
							)
						}
					/>
				)}
				{canSeeUnAssignLicenseButton(license) && (
					<DefaultButton
						className={actionButtonClass}
						data-testid="licenses-list-row__unassign-button"
						text={t('licenses-list__button-unassign')}
						onClick={() => {
							unassignLicenseHandler(license);
						}}
					/>
				)}
			</>
		);
	}

	function onRenderActivationDate(license: DisplayedLicense): JSX.Element {
		if (licenseIsNotActivated(license)) {
			return (
				<div data-testid="licenses-list-row__activation-date">
					{licenseActivationIsExpired(license)
						? t('licenses-list__activation-expired')
						: t('licenses-list__not-activated')}
					<br />
					<Text variant="tiny">
						{`${t('licenses-list__latest-activation-date')}: ${
							license.created
						}`}
					</Text>
				</div>
			);
		}

		if (licenseHasActivationScheduledInFuture(license)) {
			return (
				<Badge tone="positive" data-testid="licenses-list-row__activation-date">
					{`${t('licenses-list__scheduled-activation-date')}: ${
						license.activated
					}`}
				</Badge>
			);
		}

		return (
			<Badge tone="neutral" data-testid="licenses-list-row__activation-date">
				{license.activated}
			</Badge>
		);
	}

	function onRenderExpirationDate(license: DisplayedLicense): JSX.Element {
		const dataTestId = 'licenses-list-row__expiration-date';
		if (!license.activated) {
			return <div data-testid={dataTestId} />;
		}

		const licenseWarningMonthsThreshold = 2;
		const currentDate = new Date();
		const expirationDate = new Date(license.expirationDate);
		let tone = 'neutral' as Tone;
		if (currentDate > expirationDate) {
			tone = 'negative';
		} else {
			currentDate.setMonth(
				currentDate.getMonth() + licenseWarningMonthsThreshold
			);
			if (currentDate > expirationDate) {
				tone = 'warning';
			}
		}

		return (
			<Badge tone={tone} data-testid={dataTestId}>
				{license.expirationDate}
			</Badge>
		);
	}

	const resolveLicenseListPresentation = () => {
		if (!data.list.licensesList) {
			return generateLoading(16);
		}

		if (
			data.list.licensesList.length === 0 ||
			(data.searchedTerm !== '' && data.list.filteredLicenses.length === 0)
		) {
			return (
				<p data-testid="empty-licenses-list">
					{t('licenses-list__empty-list')}
				</p>
			);
		}

		return (
			<DetailsList
				data-testid="licenses-list"
				minRowHeight="3.5rem"
				cellMargin="auto"
				items={data.list.shownLicenses}
				columns={data.listProps.columns}
				selectionMode={SelectionMode.none}
				layoutMode={DetailsListLayoutMode.justified}
			/>
		);
	};

	return (
		<>
			<div className={classes.contentClass}>
				<Stack tokens={{ childrenGap: 10 }}>
					<HeadingWithSearch
						data-testid="license-list__heading"
						headingTitle={data.listProps.licensesListHeaderText}
						searchBoxPlaceholder={t('licenses-list__search-box__placeholder')}
						searchedTerm={data.searchedTerm}
						setSearchedTerm={operations.setSearchedTerm}
					/>
					<LicensesAssignmentModal
						{...data.assignLicenseModalInfo}
						onDismiss={() => {
							operations.setAssignLicenseModalInfo((prev) => ({
								...prev,
								isOpen: false,
							}));
						}}
					/>
					<Modal
						isOpen={isUnassigningLicenseModalOpen}
						onDismiss={() => {
							setIsUnassigningLicenseModalOpen(false);
						}}
					>
						<LicensesUnassignmentModal
							license={selectedLicense}
							onDismiss={() => setIsUnassigningLicenseModalOpen(false)}
							mutateLicenses={data.assignLicenseModalInfo.mutateLicenses}
						></LicensesUnassignmentModal>
					</Modal>
					<Stack
						tokens={{ childrenGap: 10 }}
						horizontal
						horizontalAlign="start"
						verticalAlign="center"
					>
						<span>{t('licenses-list__color-legend')}</span>
						<Badge data-testid="legend-scheduled" tone="positive">
							{t('licenses-list__activation-scheduled')}
						</Badge>{' '}
						<Badge data-testid="legend-expired" tone="negative">
							{t('licenses-list__license-expired')}
						</Badge>{' '}
						<Badge data-testid="legend-expiring-soon" tone="warning">
							{t('licenses-list__license-expiring-soon')}
						</Badge>
					</Stack>
					{data.licensesFetcherError ? (
						<p data-testid="licenses-fetch-error">
							<ErrorMessageBar error={data.licensesFetcherError.response} />
						</p>
					) : (
						resolveLicenseListPresentation()
					)}
				</Stack>
				<div
					data-testid="loader-trigger"
					className={`${classes.loaderTriggerClass} ${
						!data.list.isLicenseListCompletelyShown && classes.visible
					}`}
					ref={data.listProps.loaderTriggerRef}
				/>
			</div>
		</>
	);
};
export default LicensesList;

import React from 'react';
import { Modal } from '@fluentui/react';
import LicensesAssignment from './LicensesAssignment';
import { LicensesAssignmentProps } from './useLicensesAssignment';

const LicensesAssignmentModal: React.FC<
	LicensesAssignmentProps & { onDismiss: () => void }
> = (props) => {
	return (
		<>
			<Modal isOpen={props.isOpen} onDismiss={props.onDismiss}>
				<LicensesAssignment {...props} />
			</Modal>
		</>
	);
};

export default LicensesAssignmentModal;

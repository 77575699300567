import React, { useCallback, useState } from 'react';
import { IDropdownOption, mergeStyleSets } from '@fluentui/react';
import { useApiContext } from '../../../libs/apiContext';
import { useForm } from 'react-hook-form';
import { useUserContext } from '../../../libs/userContext';
export type FormData = {
	email: string;
	deviceId: string;
	description: string;
};

const classes = mergeStyleSets({
	content: {
		width: 'calc(100vw - 5rem)',
		maxWidth: '40rem',
		padding: '1rem',
	},
	introduction: {
		margin: '1rem 0',
		fontSize: '1.2rem',
	},
	successHeadingStackItem: {
		fontWeight: 'bold',
		fontSize: '110%',
	},
	successInformationStackItem: {
		textAlign: 'center',
	},
});

const generalRequestOptionKey = 'general_request';
const emailUniqueKey = 'supportTicket.email';
const requestHelpWithUniqueKey = 'supportTicket.requestHelpWith';
const descriptionUniqueKey = 'supportTicket.description';

const useRequestHelpForm = () => {
	const api = useApiContext();
	const { userInfo } = useUserContext();
	const [sendingTicketState, setSendingTicketState] = useState<{
		value: 'idle' | 'inProgress' | 'success';
		error?: Api.ErrorResponse;
	}>({ value: 'idle' });
	const [backendValidations, setBackendValidations] = useState<Api.Errors>();
	const [selectedRequestHelpWith, setSelectedRequestHelpWith] = useState(
		sessionStorage.getItem(requestHelpWithUniqueKey) || generalRequestOptionKey
	);
	const maxSupportTicketTextLength = 3800;
	const { handleSubmit, control } = useForm<FormData>({
		defaultValues: {
			email: sessionStorage.getItem(emailUniqueKey) || userInfo.email || '',
			description: sessionStorage.getItem(descriptionUniqueKey) || '',
		},
		reValidateMode: 'onSubmit',
		mode: 'all',
	});

	const sendSupportTicket = (payload: FormData) => {
		setSendingTicketState({ value: 'inProgress' });
		api
			.post('/helpRequest', payload)
			.then(() => {
				setSendingTicketState({ value: 'success', error: undefined });
				sessionStorage.removeItem(emailUniqueKey);
				sessionStorage.removeItem(requestHelpWithUniqueKey);
				sessionStorage.removeItem(descriptionUniqueKey);
			})
			.catch((err) => {
				const responseData = err.response as Api.ErrorResponse;
				if (responseData?.data?.specificStatusCode === '400.02') {
					setBackendValidations(responseData.data.errors);
				}
				setSendingTicketState({
					value: 'idle',
					error: err.response ? err.response : err,
				});
			});
	};

	const onSubmitHandler = (data: FormData) => {
		data.deviceId =
			selectedRequestHelpWith === generalRequestOptionKey
				? ''
				: selectedRequestHelpWith;
		sendSupportTicket(data);
	};

	const backWithSessionStorage = (key: string, value?: string) => {
		if (value) {
			sessionStorage.setItem(key, value);
		} else {
			sessionStorage.removeItem(key);
		}
	};

	const onChangeEmail = (
		e: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>,
		value?: string
	) => {
		backWithSessionStorage(emailUniqueKey, value);
	};

	const onChangeRequestHelpWith = useCallback(
		(e: React.FormEvent<HTMLDivElement>, value?: IDropdownOption) => {
			if (value) {
				setSelectedRequestHelpWith(value.key.toString());
			}
			backWithSessionStorage(requestHelpWithUniqueKey, value?.key.toString());
		},
		[]
	);

	const onChangeDescription = (
		e: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>,
		value?: string
	) => {
		backWithSessionStorage(descriptionUniqueKey, value);
	};

	return {
		data: {
			maxSupportTicketTextLength,
			sendingTicketState,
			selectedRequestHelpWith,
			generalRequestOptionKey,
			backendValidations,
		},
		classes,
		operations: {
			handleSubmit,
			onSubmitHandler,
			formControl: control,
			onChangeEmail,
			onChangeRequestHelpWith,
			onChangeDescription,
		},
	};
};

const ExportFunction = (
	...params: Parameters<typeof useRequestHelpForm>
): ReturnType<typeof useRequestHelpForm> => useRequestHelpForm(...params);
export default ExportFunction;

import {
	Dropdown,
	getTheme,
	Icon,
	IDropdownOption,
	IStackTokens,
	mergeStyles,
	mergeStyleSets,
	Shimmer,
	Stack,
	TooltipHost,
} from '@fluentui/react';
import { useIntl } from '@ysoft/react-intl';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import SearchableDropdown from '../../general-components/SearchableDropdown';
import { useApiContext } from '../../libs/apiContext';
import { useUserContext } from '../../libs/userContext';
import {
	loadFromLocalStorage,
	loadFromLocalStorageOrSaveDefault,
	saveToLocalStorage,
} from '../../libs/localStorageHelper';
import { currentTenantLocalStorageKey } from '../../support/constants';

const theme = getTheme();

const spacing: IStackTokens = {
	childrenGap: '0.5rem',
};

const iconClass = mergeStyles({
	fontSize: 25,
});

const classes = mergeStyleSets({
	dropdown: {
		width: '11em',
	},
	dropdownSearch: {
		paddingInlineStart: 0,
	},
	dropdownShimmerStack: {
		height: '100%',
	},
	iconYellowDark: [{ color: theme.palette.yellowDark }, iconClass],
});

const TenantsHeaderList: React.FC = () => {
	const api = useApiContext();
	const { userInfo, isPartner, hasTenantId } = useUserContext();
	const { t } = useIntl();
	const tooltipId = 'fetchTenantsError';
	const tenantDropdownId = 'header_tenant-dropdown';
	const currentUserTenantKey = `${currentTenantLocalStorageKey}-${userInfo.preferred_username}`;
	let currentTenant = loadFromLocalStorage(currentUserTenantKey);

	const [selectedItem, setSelectedItem] = useState<IDropdownOption>();

	const tenantsFetcher = (url: string) => api.get(url).then((res) => res.data);

	const { data: tenantsInfo, error: tenantsFetchError } =
		useSWR<Api.ManagedTenantsInfo>('/header/getManagedTenants', tenantsFetcher);

	if (userInfo.tenant_id && tenantsInfo) {
		currentTenant = loadFromLocalStorageOrSaveDefault(
			currentUserTenantKey,
			userInfo.tenant_id
		);
		if (!tenantsInfo.tenants?.some((t) => t.tenantId === currentTenant)) {
			saveToLocalStorage(currentUserTenantKey, userInfo.tenant_id);
			currentTenant = userInfo.tenant_id;
		}
	}

	const onTenantSwitch = (
		_: React.FormEvent<HTMLDivElement>,
		option: IDropdownOption | undefined
	): void => {
		setSelectedItem(option);
	};

	const getSortedTenants = (
		tenantsInfo: Api.ManagedTenantsInfo | undefined
	): IDropdownOption[] => {
		if (!tenantsInfo?.tenants) {
			return [];
		}

		const tenants = tenantsInfo.tenants?.map((tenant) => ({
			key: tenant.tenantId,
			text: tenant.name,
		}));
		const homeTenant = tenants.find((t) => t.key === userInfo.tenant_id);

		tenants.sort((a, b) => (a.text > b.text ? 1 : -1));
		if (homeTenant) {
			tenants.splice(tenants.indexOf(homeTenant), 1);
			tenants.unshift(homeTenant);
		}

		return tenants;
	};

	const getPlaceholder = (
		tenantsInfo: Api.ManagedTenantsInfo | undefined
	): string | undefined => {
		const currentTenant = loadFromLocalStorage(currentUserTenantKey);

		if (currentTenant) {
			return tenantsInfo?.tenants?.find((t) => t.tenantId === currentTenant)
				?.name;
		}

		return isPartner && !hasTenantId
			? t('header__dropdown__select-customer__placeholder')
			: undefined;
	};

	useEffect(() => {
		if (selectedItem) {
			saveToLocalStorage(currentUserTenantKey, selectedItem.key.toString());
			window.location.reload();
		}
	}, [currentUserTenantKey, selectedItem]);

	const fetchErrorDropdown: JSX.Element = (
		<Stack horizontal verticalAlign="center" tokens={spacing}>
			<TooltipHost content={t('tenants-list__fetch-error')} id={tooltipId}>
				<Icon
					data-testid="header_warning-icon"
					iconName="Warning"
					className={classes.iconYellowDark}
					color={theme.palette.yellowDark}
					aria-describedby={tooltipId}
				/>
			</TooltipHost>
			<Dropdown
				data-testid={tenantDropdownId}
				placeholder={userInfo.tenant_name}
				options={[]}
				disabled
				dropdownWidth="auto"
				className={classes.dropdown}
			/>
		</Stack>
	);

	const loadingDropdown = (
		<Stack.Item align="center">
			<Dropdown
				options={[]}
				disabled
				className={classes.dropdown}
				onRenderPlaceholder={() => (
					<Stack
						verticalAlign="center"
						className={classes.dropdownShimmerStack}
					>
						<Shimmer />
					</Stack>
				)}
			/>
		</Stack.Item>
	);

	const loadedDropdown = (): JSX.Element => {
		return (
			<Stack.Item align="center">
				<SearchableDropdown
					data-testid={tenantDropdownId}
					className={classes.dropdown}
					dropdownWidth="auto"
					placeholder={getPlaceholder(tenantsInfo) || userInfo.tenant_name}
					selectedKey={selectedItem ? selectedItem.key : undefined}
					options={getSortedTenants(tenantsInfo)}
					disabled={!tenantsInfo?.canSwitchBetweenTenants}
					onChange={onTenantSwitch}
					noResultsFoundMessage={t('tenants-list__search-no-results')}
					searchBoxProps={{ showIcon: true, className: classes.dropdownSearch }}
				/>
			</Stack.Item>
		);
	};

	return tenantsFetchError ? (
		<>{fetchErrorDropdown}</>
	) : !tenantsInfo ? (
		<>{loadingDropdown}</>
	) : (
		<>{loadedDropdown()}</>
	);
};

export default TenantsHeaderList;

import React from 'react';
import { Modal } from '@fluentui/react';
import LoadAnotherDeviceConfiguration, {
	LoadAnotherDeviceConfigurationProps,
} from './LoadAnotherDeviceConfiguration';

const LoadAnotherDeviceConfigurationModal: React.FC<
	LoadAnotherDeviceConfigurationProps
> = (props) => {
	return (
		<Modal isOpen={props.isOpen} onDismiss={props.onDismiss}>
			<LoadAnotherDeviceConfiguration {...props} />
		</Modal>
	);
};

export default LoadAnotherDeviceConfigurationModal;

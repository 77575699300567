import React, { useEffect, useState } from 'react';
import {
	Breadcrumb,
	MessageBar,
	MessageBarType,
	Spinner,
	SpinnerSize,
	Stack,
} from '@fluentui/react';
import { useIntl } from '@ysoft/react-intl';
import { ErrorMessageBar } from '../../general-components/ErrorMessageBar';
import { generateLoading } from '../../libs/generateLoading';
import Configuration from './Configuration';
import { IBreadcrumbItem, mergeStyleSets } from '@fluentui/react';
import { useApiContext } from '../../libs/apiContext';
import useSWR, { SWRConfiguration } from 'swr';
import { useHistory, useParams } from 'react-router-dom';
import { useConfigContext } from '../../config/configContext';

type PortConfigurationParams = {
	id: string;
};

export const DeviceDetailPage: React.FC = () => {
	const { t } = useIntl();
	const api = useApiContext();
	const [longLoading, setLongLoading] = useState(false);
	const { id } = useParams<PortConfigurationParams>();
	const history = useHistory();
	const { config } = useConfigContext();

	const stackClasses = mergeStyleSets({
		formContainer: {
			minHeight: '90vh',
		},
	});

	const fetcher = (url: string) => api.get(url).then((res) => res.data);

	const swrOptions: SWRConfiguration = {
		revalidateOnFocus: false,
		loadingTimeout: 2000,
		onLoadingSlow: () => setLongLoading(true),
	};

	const {
		data: moduleTwin,
		error: moduleTwinError,
		mutate: mutateModuleTwin,
	} = useSWR<Api.ModuleTwin>(`/configuration/${id}`, fetcher, swrOptions);

	const licenseFetcher = (
		url: string
	): Promise<Api.License | Record<string, never>> =>
		config.licenseToggle
			? api.get(url).then((res) => res.data)
			: new Promise((resolve, _) => {
					resolve({});
			  });
	const {
		data: license,
		error: licenseError,
		mutate: mutateValidLicense,
	} = useSWR<Api.License | Record<string, never>>(
		`/Licenses/ValidDeviceLicense/${id}`,
		licenseFetcher,
		{
			...swrOptions,
			dedupingInterval: 0,
		}
	);

	useEffect(() => {
		setLongLoading(false);
	}, [moduleTwinError, moduleTwin]);

	const hasUnsupportedLicense = (): boolean => {
		return (license && license.type === 'Unknown') ?? false;
	};

	const back = () => {
		history.push('/devices');
	};

	const breadcrumbItems: IBreadcrumbItem[] = [
		{
			text: t('port-configuration__breadcrumb-text'),
			key: 'devices',
			onClick: back,
		},
		{
			text: id,
			key: 'id',
			isCurrentItem: true,
		},
	];

	const breadcrumbStyles = {
		itemLink: {
			color: '#0077d4',
			selectors: {
				':hover': {
					backgroundColor: '#e9f6f9',
					color: '#0000ee',
					textDecoration: 'underline',
				},
				':hover:focus': {
					backgroundColor: '#e9f6f9',
					color: '#0000ee',
				},
			},
		},
	};

	return moduleTwinError ? (
		<ErrorMessageBar
			data-testid="port-configuration__error-message-bar"
			error={moduleTwinError?.response ?? licenseError?.response}
		/>
	) : moduleTwin && (license || licenseError) ? (
		license && hasUnsupportedLicense() ? (
			<MessageBar
				messageBarType={MessageBarType.error}
				data-testid="port-configuration__error-message-bar"
			>
				{t('port-configuration__unsupported-license')}
			</MessageBar>
		) : (
			<>
				<Stack
					data-testid="port-configuration"
					className={stackClasses.formContainer}
					tokens={{ childrenGap: 8 }}
				>
					<Stack.Item>
						<Breadcrumb
							data-testid="port-configuration__breadcrumb"
							items={breadcrumbItems}
							styles={breadcrumbStyles}
						/>
					</Stack.Item>
					<Configuration
						deviceId={id}
						back={back}
						license={license}
						licenseError={licenseError}
						mutateValidLicense={mutateValidLicense}
						moduleTwin={moduleTwin}
						mutateModuleTwin={mutateModuleTwin}
					/>
				</Stack>
			</>
		)
	) : longLoading ? (
		<Spinner
			size={SpinnerSize.large}
			label={t('port-configuration__spinner-label')}
		/>
	) : (
		generateLoading()
	);
};

import { getTheme, mergeStyles } from '@fluentui/react';

const theme = getTheme();
const badgeClass = mergeStyles({
	padding: '0.3rem',
});

export const Badge: React.FC<BadgeProps> = ({
	tone,
	'data-testid': dataTestId,
	children,
	...htmlProps
}) => {
	return (
		<span
			{...htmlProps}
			className={tones[tone].toString()}
			data-badge-type={tone.toString()}
			data-testid={dataTestId}
		>
			{children}
		</span>
	);
};

export type BadgeProps = React.ComponentPropsWithoutRef<'span'> & {
	'data-testid': string;
	tone: Tone;
};

export type Tone = keyof Tones;

class Tones {
	negative = mergeStyles(badgeClass, {
		backgroundColor: theme.semanticColors.errorBackground,
	});

	positive = mergeStyles(badgeClass, {
		backgroundColor: theme.semanticColors.successBackground,
	});

	warning = mergeStyles(badgeClass, {
		backgroundColor: theme.semanticColors.warningBackground,
	});

	neutral = mergeStyles(badgeClass, {
		backgroundColor: theme.palette.neutralLight,
	});
}

const tones = new Tones();

import React from 'react';
import {
	PrimaryButton,
	IButtonProps,
	Stack,
	SpinnerSize,
	Spinner,
} from '@fluentui/react';

const ProgressButton: React.FC<IButtonProps> = (props) => {
	return (
		<PrimaryButton disabled data-testid="progress-button" {...props}>
			<Stack horizontal tokens={{ childrenGap: 10 }}>
				<Stack.Item>
					<Spinner size={SpinnerSize.medium} />
				</Stack.Item>
				<Stack.Item>{props.children}</Stack.Item>
			</Stack>
		</PrimaryButton>
	);
};

export default ProgressButton;

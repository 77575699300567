import React from 'react';
import useApp from './useApp';
import DevicesList from './components/devices-list/DevicesList';
import LicensesList from './components/licenses/LicensesList';
import Documentation from './components/documentation/Documentation';
import { Header } from './components/header/Header';
import { ContentWrapper } from './components/ContentWrapper';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useIntl } from '@ysoft/react-intl';
import { Stack } from '@fluentui/react';
import useAuth from './libs/auth';
import { DeviceDetailPage } from './components/port-configuration/DeviceDetailPage';
import Menu from './components/menu/Menu';
import { useConfigContext } from './config/configContext';

const App: React.FC = () => {
	const { classes } = useApp();
	const { t } = useIntl();
	const { keycloak } = useAuth();
	const { config } = useConfigContext();

	return keycloak.authenticated ? (
		<div className={classes.appClass}>
			<title>{t('app__title')}</title>
			<header>
				<Header />
			</header>

			<Menu isMobile={true} />

			<Stack horizontal wrap>
				<Stack.Item disableShrink className={classes.navStackItemClass}>
					<Menu isMobile={false} />
				</Stack.Item>
				<Stack.Item className={classes.contentStackItemClass}>
					<ContentWrapper className={classes.wrapperClass}>
						<Switch>
							<Route exact path={'/'}>
								<Redirect to={'/devices'} />
							</Route>
							<Route path={'/devices/:id'} component={DeviceDetailPage} />
							<Route path={'/devices'} component={DevicesList} />
							{config.licenseToggle && (
								<Route path={'/licenses'} component={LicensesList} />
							)}
							<Route path={'/documentation/:path*'} component={Documentation} />
						</Switch>
					</ContentWrapper>
				</Stack.Item>
			</Stack>
		</div>
	) : (
		<div />
	);
};

export default App;
